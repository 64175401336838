/* fonts */
@font-face {
  font-family: 'Saira Semi Condensed';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("../webfonts/U9MN6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXdvaOM.ttf") format('truetype');
}

@font-face {
  font-family: 'Saira Semi Condensed';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("../webfonts/U9MM6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXfDScMW.ttf") format('truetype');
}

@font-face {
  font-family: 'Saira Semi Condensed';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../webfonts/U9MM6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXenSsMW.ttf") format('truetype');
}

@font-face {
  font-family: 'Saira Semi Condensed';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../webfonts/U9MD6c-2-nnJkHxyCjRcnMHcWVWV1cWRRU8L.ttf") format('truetype');
}

@font-face {
  font-family: 'Saira Semi Condensed';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../webfonts/U9MM6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXf_S8MW.ttf") format('truetype');
}

@font-face {
  font-family: 'Saira Semi Condensed';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../webfonts/U9MM6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXfTTMMW.ttf") format('truetype');
}

@font-face {
  font-family: 'Saira Semi Condensed';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../webfonts/U9MM6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXe3TcMW.ttf") format('truetype');
}

@font-face {
  font-family: 'Saira Semi Condensed';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("../webfonts/U9MM6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXerTsMW.ttf") format('truetype');
}

@font-face {
  font-family: 'Saira Semi Condensed';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("../webfonts/U9MM6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXePT8MW.ttf") format('truetype');
}

/* end fonts */
/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Utility
# Cards
# Common
# Form
# Navigations
# Animations
# Mobile Nav
# Search Popup
# Page Header
# Google Map
# Client Carousel
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Common
--------------------------------------------------------------*/
:root {
  --thm-font: 'Saira Semi Condensed', sans-serif;
  --thm-gray: #878c8f;
  --thm-gray-rgb: 135, 140, 143;
  --thm-base: #2c1f4a;
  --thm-base2: #e67817;
  --thm-base-rgb: 221, 4, 41;
  --thm-black: #1a1a1a;
  --thm-black-rgb: 26, 26, 26;
}

.auto-container {
  position: static;
  max-width: 1200px;
  padding: 0px 15px;
  margin: 0 auto;
}

.row {
  --bs-gutter-x: 30px;
}

.gutter-y-30 {
  --bs-gutter-y: 30px;
}

body {
  color: var(--thm-black);
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  font-family: var(--thm-font);
}

body.locked {
  overflow: hidden;
}

a {
  color: var(--thm-black);
}

a,
a:hover,
a:focus,
a:visited {
  text-decoration: none;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: 1;
}

:-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

::-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

::placeholder {
  color: inherit;
  opacity: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--thm-black);
  font-family: var(--thm-font);
  margin: 0;
}

p {
  margin: 0;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 0;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: 1;
}

:-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

::-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

::placeholder {
  color: inherit;
  opacity: 1;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
/***
=============================================
    Form Validation Styles
=============================================
***/
input:focus,
textarea:focus,
select:focus {
  border-color: var(--thm-base);
  outline: none;
}

#contact-form input[type="text"].error {
  border-color: red;
}

#contact-form input[type="email"].error {
  border-color: red;
}

#contact-form select.error {
  border-color: red;
}

#contact-form textarea.error {
  border-color: red;
}

#contact-form label.error {
  display: none !important;
}


.page-wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: 300px;
  overflow: hidden;
}

.container {
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: 1;
}

:-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

::-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

::placeholder {
  color: inherit;
  opacity: 1;
}

button {
  cursor: pointer;
  border: none;
  background: transparent;
  padding: 0;
}


ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

ol,
li {
  margin: 0;
  padding: 0;
}

/***
=============================================
    Rating Box 
=============================================
***/
.rating-box {
  position: relative;
  display: block;
  overflow: hidden;
}

.rating-box ul {
  overflow: hidden;
}

.rating-box ul li {
  position: relative;
  display: inline-block;
  margin-right: 0px;
  line-height: 0;
}

.rating-box ul li:last-child {
  margin-right: 0;
}

.rating-box ul li span {
  position: relative;
  display: inline-block;
  color: var(--thm-base);
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  cursor: pointer;
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

/***
=============================================
   Sec Title 
=============================================
***/
.sec-title {
  position: relative;
  display: block;
  margin-top: -10px;
  padding-bottom: 46px;
}

.sec-title h2 {
  color: var(--thm-black);
  font-size: 55px;
  line-height: 1.2em;
  font-weight: 700;
  font-family: var(--thm-font);
}

.sec-title.text-white h2 {
  color: #ffffff;
}

/***
=============================================
   Sec Title Two
=============================================
***/
.sec-title-two {
  position: relative;
  display: block;
  margin-top: -7px;
  padding-bottom: 30px;
}

.sec-title-two .sub-title {
  position: relative;
  display: inline-block;
  padding-bottom: 13px;
}

.sec-title-two .sub-title .round-one {
  position: absolute;
  top: 9px;
  left: -25px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid var(--thm-base2);
  content: "";
  margin: 0 auto;
}

.sec-title-two .sub-title .round-one::before {
  position: absolute;
  top: 3px;
  left: -32px;
  width: 25px;
  height: 2px;
  background: var(--thm-base2);
  content: "";
}

.sec-title-two .sub-title .round-two {
  position: absolute;
  top: 9px;
  right: -25px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid var(--thm-base2);
  content: "";
  margin: 0 auto;
}

.sec-title-two .sub-title .round-two::before {
  position: absolute;
  top: 3px;
  right: -32px;
  width: 25px;
  height: 2px;
  background: var(--thm-base2);
  content: "";
}

.sec-title-two .sub-title h3 {
  color: var(--thm-base2);
  font-size: 22px;
  line-height: 32px;
  font-weight: 600;
  text-transform: capitalize;
}

.sec-title-two h1 {
  color: var(--thm-base2);
  font-size: 55px;
  line-height: 1.2em;
  font-weight: 700;
  text-transform: capitalize;
  letter-spacing: -0.01em;
  font-family: var(--thm-font);
}

.sec-title-two h1 span {
  color: var(--thm-base);
}

.sec-title-two h2 {
  color: var(--thm-base);
  font-size: 55px;
  line-height: 1.2em;
  font-weight: 700;
  text-transform: capitalize;
  letter-spacing: -0.01em;
  font-family: var(--thm-font);
}

/***
=============================================
   Sec Title Three
=============================================
***/
.sec-title-three {
  position: relative;
  display: block;
  margin-top: -7px;
  padding-bottom: 48px;
}

.sec-title-three__tagline {
  position: relative;
  display: block;
  margin-bottom: 6px;
}

.sec-title-three__tagline p {
  color: var(--thm-base);
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.sec-title-three h2 {
  font-size: 45px;
  line-height: 55px;
  font-weight: 600;
  text-transform: capitalize;
}


/***
=============================================
   Thm Btn
=============================================
***/
.thm-btn {
  position: relative;
  display: inline-block;
  border-radius: 10px;
  padding: 0px 45px 0px;
  line-height: 65px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  background: var(--thm-black);
  overflow: hidden;
  font-family: var(--thm-font);
  text-transform: capitalize;
  transition: all 0.5s ease-in-out 0.2s;
  z-index: 1;
}

.thm-btn:before {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  content: '';
  margin: auto;
  background-color: var(--thm-base);
  transition: all .5s;
  z-index: -1;
  border-radius: 8px;
}

.thm-btn:hover:before {
  transform: rotateX(90deg);
}

.thm-btn::after {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  content: '';
  margin: auto;
  background-color: var(--thm-base);
  transition: all .5s;
  z-index: -1;
  border-radius: 8px;
}

.thm-btn:hover::after {
  transform: rotateY(90deg);
}

.thm-btn2 {
  position: relative;
  display: inline-block;
  border-radius: 10px;
  padding: 0px 45px 0px;
  line-height: 65px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  background: var(--thm-black);
  overflow: hidden;
  font-family: var(--thm-font);
  text-transform: capitalize;
  transition: all 0.5s ease-in-out 0.2s;
  z-index: 1;
}

.thm-btn2:before {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  content: '';
  margin: auto;
  background-color: var(--thm-base2);
  transition: all .5s;
  z-index: -1;
  border-radius: 10px;
}

.thm-btn2:hover:before {
  transform: rotateX(90deg);
}

.thm-btn2::after {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  content: '';
  margin: auto;
  background-color: var(--thm-base2);
  transition: all .5s;
  z-index: -1;
  border-radius: 10px;
}

.thm-btn2:hover::after {
  transform: rotateY(90deg);
}

.thm-btn .txt {
  position: relative;
  z-index: 1;
}

.thm-btn i::before {
  position: relative;
  display: inline-block;
  color: #ffffff;
  font-size: 14px;
  top: 1px;
  font-weight: 700;
}

.thm-btn:hover,
.thm-btn:focus,
.thm-btn2:hover,
.thm-btn2:focus {
  color: #ffffff;
}



/***
=============================================
   Owl Dot Style1
=============================================
***/
.owl-carousel.owl-dot-style1 .owl-dots {
  position: relative;
  text-align: center;
  line-height: 0;
  margin-top: 50px !important;
  display: block;
}

.owl-carousel.owl-dot-style1 .owl-dots .owl-dot {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 5px;
  background-color: #e7e7e7;
  border: 0px solid #a5a5a5;
  margin: 0px 10px;
  padding: 0px;
  border-radius: 0%;
  transition: all 100ms linear;
  transition-delay: 0.1s;
}

.owl-carousel.owl-dot-style1 .owl-dots .owl-dot:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background: var(--thm-base);
  border-radius: 0%;
  transform: scale(0);
  transition: all 500ms ease;
}


.owl-carousel.owl-dot-style1 .owl-dots .owl-dot.active:before {
  transform: scale(1.0);
}

.owl-carousel.owl-dot-style1 .owl-dots .owl-dot span {
  display: none;
}


img {
  max-width: 100%;
  height: auto;
  transition-delay: .1s;
  transition-timing-function: ease-in-out;
  transition-duration: .7s;
  transition-property: all;
}

/***
=============================================
   Scroll To Top
=============================================
***/
.scroll-to-top {
  position: fixed;
  right: 15px;
  bottom: 80px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: var(--thm-base);
  border: 2px solid var(--thm-base);
  color: #ffffff;
  font-size: 20px;
  line-height: 56px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  display: none;
  cursor: pointer;
  transform: rotate(0deg);
  transition: all 200ms linear;
  transition-delay: 0.1s;
  z-index: 999999999;
}

.scroll-to-top:after {
  position: absolute;
  content: '';
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 1;
  z-index: -1;
  background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 80%);
  background: -webkit-radial-gradient(center ellipse, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 80%);
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 80%);
}

.scroll-to-top:before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: transparent;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-animation-delay: .9s;
  animation-delay: .9s;
  content: "";
  -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.9);
  -webkit-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.scroll-to-top:hover {
  color: #ffffff;
  background-color: var(--thm-black);
  border-color: var(--thm-black);
}

.scroll-to-top i::before {
  position: relative;
  display: inline-block;
  transform: rotate(180deg);
}


/***
=============================================
   Styled Pagination
=============================================
***/
.styled-pagination {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  padding-top: 20px;
}

.styled-pagination li {
  position: relative;
  display: inline-block;
  margin-right: 15px;
}

.styled-pagination li:last-child {
  margin-right: 0;
}

.styled-pagination li a {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 60px;
  background: transparent;
  border-radius: 50%;
  color: var(--thm-black);
  font-size: 16px;
  line-height: 60px;
  font-weight: 500;
  border: 1px solid #dee0e2;
  text-align: center;
  transition: all 500ms ease;
  font-family: var(--thm-font);
  z-index: 1;
}

.styled-pagination li:hover a,
.styled-pagination li.active a {
  color: #ffffff;
  background: var(--thm-base);
  border-color: var(--thm-base);
}

.styled-pagination li.prev a,
.styled-pagination li.next a {
  border-radius: 50%;
  color: var(--thm-base);
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.styled-pagination li.prev a:hover,
.styled-pagination li.next a:hover {
  color: #ffffff;
}

.styled-pagination li a span:before {
  position: relative;
  top: 1px;
  color: var(--thm-black);
  font-size: 17px;
  font-weight: 700;
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.styled-pagination li a:hover span:before,
.styled-pagination li.active a span:before {
  color: #ffffff;
}


.checked-box1 {
  position: relative;
  display: block;
  min-height: 20px;
}

.checked-box1 label {
  position: relative;
  display: inline-block;
  padding-left: 30px;
  margin-right: 0px;
  margin-bottom: 0;
  color: var(--thm-gray);
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  cursor: pointer;
  min-height: 20px;
  font-family: var(--thm-font);
}

.checked-box1 input[type="checkbox"] {
  display: none;
}

.checked-box1 input[type="checkbox"]+label span {
  position: absolute;
  top: 0px;
  left: 0;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  background-color: transparent;
  border: 1px solid #80868d;
  cursor: pointer;
  border-radius: 4px;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.checked-box1 label span:before {
  font-family: 'icomoon' !important;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0;
  right: 0;
  content: "\e922";
  font-size: 10px;
  font-weight: 700;
  width: 20px;
  height: 20px;
  margin: 0px;
  opacity: 0;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checked-box1 input[type="checkbox"]:checked+label span {
  border-color: #80868d;
}

.checked-box1 input[type="checkbox"]:checked+label span:before {
  opacity: 1.0;
}


/***
=============================================
   Search Popup
=============================================
***/
.search-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  padding-left: 20px;
  padding-right: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: translateY(-110%);
  transform: translateY(-110%);
  -webkit-transition: opacity 500ms ease, -webkit-transform 500ms ease;
  transition: opacity 500ms ease, -webkit-transform 500ms ease;
  transition: transform 500ms ease, opacity 500ms ease;
  transition: transform 500ms ease, opacity 500ms ease, -webkit-transform 500ms ease;
}

.search-popup.active {
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
}

.search-popup__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--thm-black);
  opacity: 0.75;
  cursor: pointer;
}

.search-popup__content {
  width: 100%;
  max-width: 560px;
}

.search-popup__content form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
}

.search-popup__content form input[type="search"],
.search-popup__content form input[type="text"] {
  width: 100%;
  background-color: #fff;
  font-size: 16px;
  border: none;
  outline: none;
  height: 66px;
  padding-left: 30px;
  padding-right: 75px;
}

.search-popup__content .thm-btn {
  padding: 0;
  width: 68px;
  height: 68px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 0;
  right: -1px;
  border-radius: 0;
  background-color: var(--thm-base);
  border: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.search-popup__content .thm-btn::before {
  border-radius: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.search-popup__content .thm-btn i::before {
  position: relative;
  display: inline-block;
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
  background-color: transparent;
  margin-left: 0;
  z-index: 5;
}


.bootstrap-select .btn-light:not(:disabled):not(.disabled).active,
.bootstrap-select .btn-light:not(:disabled):not(.disabled):active,
.bootstrap-select .show>.btn-light.dropdown-toggle {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.bootstrap-select>.dropdown-toggle {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.bootstrap-select .dropdown-toggle:focus,
.bootstrap-select>select.mobile-device:focus+.dropdown-toggle {
  outline: none !important;
}

.bootstrap-select .dropdown-menu {
  border: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  z-index: 991;
  border-radius: 0;
}

.bootstrap-select .dropdown-menu>li+li>a {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.bootstrap-select .dropdown-menu>li.selected>a {
  background: var(--brote-primary);
  color: #fff;
}

.bootstrap-select .dropdown-menu>li>a {
  font-size: 16px;
  font-weight: 500;
  padding: 4px 20px;
  color: #ffffff;
  background: var(--brote-base);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.bootstrap-select .dropdown-menu>li>a:hover {
  background: var(--brote-white);
  color: var(--brote-base);
  cursor: pointer;
}


/* Preloader Css*/
#pre-load {
  background-color: #fff;
  height: 100%;
  width: 100%;
  position: fixed;
  margin-top: 0px;
  top: 0px;
  z-index: 9999;
}

.loader .loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  border: 3px solid #ebebec;
  border-radius: 50%;
}

.loader .loader-container:before {
  position: absolute;
  content: "";
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  border-top: 3px solid #e67817;
  border-radius: 50%;
  animation: loaderspin 1.8s infinite ease-in-out;
  -webkit-animation: loaderspin 1.8s infinite ease-in-out;
}

.loader .loader-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  text-align: center;
}

.loader .loader-icon img {
  animation: loaderpulse alternate 900ms infinite;
  width: 55px;
}

.woocommerce .quantity input[type="number"]::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */

  color: #363636 !important;
  opacity: 1;
}

.woocommerce .quantity input[type="number"]::-moz-placeholder {
  /* Firefox 19+ */

  color: #363636 !important;
  opacity: 1;
}

.woocommerce .quantity input[type="number"]:-ms-input-placeholder {
  /* IE 10+ */

  color: #363636 !important;
  opacity: 1;
}

.woocommerce .quantity input[type="number"]:-moz-placeholder {
  /* Firefox 18- */

  color: #363636 !important;
  opacity: 1;
}

@keyframes loaderspin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@-webkit-keyframes loaderspin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes loaderpulse {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.2);
  }
}

/**/


@media screen and (max-width: 767px) {
  .handle-preloader .animation-preloader .spinner {
    height: 8em;
    width: 8em;
  }
}

@media screen and (max-width: 500px) {
  .handle-preloader .animation-preloader .spinner {
    height: 7em;
    width: 7em;
  }

  .handle-preloader .animation-preloader .txt-loading .letters-loading {
    font-size: 30px;
    letter-spacing: 10px;
  }
}



/*--------------------------------------------------------------
  # Mobile Nav
  --------------------------------------------------------------*/
.mobile-nav__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transform-origin: left center;
  transform-origin: left center;
  -webkit-transition: visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
  transition: visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
  transition: transform 500ms ease 500ms, visibility 500ms ease 500ms;
  transition: transform 500ms ease 500ms, visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
  visibility: hidden;
}

.mobile-nav__wrapper.expanded {
  opacity: 1;
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
  visibility: visible;
  -webkit-transition: visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
  transition: visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
  transition: transform 500ms ease 0ms, visibility 500ms ease 0ms;
  transition: transform 500ms ease 0ms, visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
}

.mobile-nav__wrapper .container {
  padding-left: 0;
  padding-right: 0;
}

.mobile-nav__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--thm-black);
  opacity: 0.7;
  cursor: pointer;
}

.mobile-nav__content {
  position: relative;
  width: 300px;
  background-color: #ffffff;
  height: 100%;
  overflow-y: auto;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
  transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
  transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms, transform 500ms ease 0ms;
  transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms, transform 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
  z-index: 10;
}

.mobile-nav__wrapper.expanded .mobile-nav__content {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
  transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
  transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms, transform 500ms ease 500ms;
  transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms, transform 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
}

.mobile-nav__close {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 20px;
  right: 15px;
  width: 40px;
  height: 40px;
  background-color: rgb(0 0 0 / 10%);
  color: #000000;
  font-size: 18px;
  cursor: pointer;
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.mobile-nav__close:hover {
  background-color: var(--thm-black);
}

.mobile-nav__close i:before {
  position: relative;
  display: inline-block;
  transform: rotate(45deg);
}

.mobile-nav__content .logo-box {
  margin-bottom: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.mobile-nav__container {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-nav__content .main-menu__list,
.mobile-nav__content .main-menu__list>li>ul,
.mobile-nav__content .main-menu__list>li>ul>li>ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.mobile-nav__content .main-menu__list>li>ul,
.mobile-nav__content .main-menu__list>li>ul>li>ul {
  display: none;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin-left: 20px;
}

.mobile-nav__content .main-menu__list>li:not(:last-child),
.mobile-nav__content .main-menu__list>li>ul>li:not(:last-child),
.mobile-nav__content .main-menu__list>li>ul>li>ul>li:not(:last-child) {
  border-bottom: 1px solid rgb(0 0 0 / 10%);
}

.mobile-nav__content .main-menu__list>li>a,
.mobile-nav__content .main-menu__list>li>ul>li>a,
.mobile-nav__content .main-menu__list>li>ul>li>ul>li>a {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  line-height: 30px;
  color: #000000;
  font-size: 15px;
  font-family: var(--thm-font);
  font-weight: 500;
  height: 46px;
  transition: 500ms;
}

.mobile-nav__content .main-menu__list>li.current>a {
  color: var(--thm-base2);
}

.mobile-nav__content .main-menu__list>li>ul>li.current>a {
  color: var(--thm-base2);
}

.mobile-nav__content .main-menu__list>li>ul>li>a {
  font-size: 14px;
  font-weight: 400;
}

.mobile-nav__content .main-menu__list>li>a.expanded,
.mobile-nav__content .main-menu__list>li>ul>li>a.expanded,
.mobile-nav__content .main-menu__list>li>ul>li>ul>li>a.expanded {
  color: var(--thm-base2);
}

.mobile-nav__content .main-menu__list>li>a>button,
.mobile-nav__content .main-menu__list>li>ul>li>a>button,
.mobile-nav__content .main-menu__list>li>ul>li>ul>li>a>button {
  width: 30px;
  height: 30px;
  background-color: var(--thm-black);
  border: none;
  outline: none;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transition: -webkit-transform 500ms ease;
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
  padding: 0;
}

.mobile-nav__content .main-menu__list>li>a>button.expanded,
.mobile-nav__content .main-menu__list>li>ul>li>a>button.expanded,
.mobile-nav__content .main-menu__list>li>ul>li>ul>li>a>button.expanded {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  background-color: #fff;
  color: var(--thm-base);
}


.mobile-nav__content .home-showcase__title {
  background-color: rgba(255, 255, 255, 0.1);
  margin-top: 0px;
}

.mobile-nav__contact {
  position: relative;
  display: block;
  margin-top: 40px;
  margin-bottom: 30px;
}

.mobile-nav__contact li {
  position: relative;
  display: flex;
  align-items: center;
  color: #000000;
  font-size: 15px;
  font-weight: 500;
}

.mobile-nav__contact li+li {
  margin-top: 15px;
}

.mobile-nav__contact li a {
  color: #000000;
  -webkit-transition: 500ms;
  transition: 500ms;
}

.mobile-nav__contact li a:hover {
  color: var(--thm-black);
}

.mobile-nav__contact li>i {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 0%;
  background-color: rgb(0 0 0 / 10%);
  color: #000000;
  font-size: 12px;
  margin-right: 10px;
}


.mobile-nav__top {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.mobile-nav__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.mobile-nav__social a {
  position: relative;
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgb(0 0 0 / 10%);
  color: #000000;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  transition: 500ms;
}

.mobile-nav__social a+a {
  margin-left: 10px;
}

.mobile-nav__social a:hover {
  color: var(--thm-base);
  background-color: #ffffff;
}



/* no menu after 2rd level dropdown */
.mobile-nav__content .main-menu__list>li>ul>li>ul>li>a>button,
.mobile-nav__content .main-menu__list>li>ul>li>ul>li>ul {
  display: none !important;
}



/*--------------------------------------------------------------
# Main Header Css
--------------------------------------------------------------*/
.main-header {
  position: relative;
  display: block;
  width: 100%;
  background: transparent;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 99;
}

.main-menu {
  position: relative;
  display: block;
}

.main-menu__wrapper {
  position: relative;
  display: block;
}

.main-menu__wrapper-inner {
  position: relative;
  display: flex;
  align-items: center;
}

.main-menu-box {
  display: block;
  float: right;
  margin-left: auto;
}

.main-menu .main-menu__list,
.main-menu .main-menu__list>li>ul,
.main-menu .main-menu__list>li>ul>li>ul,
.stricky-header .main-menu__list,
.stricky-header .main-menu__list>li>ul,
.stricky-header .main-menu__list>li>ul>li>ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: none;
}

@media (min-width: 1200px) {

  .main-menu .main-menu__list,
  .main-menu .main-menu__list>li>ul,
  .main-menu .main-menu__list>li>ul>li>ul,
  .stricky-header .main-menu__list,
  .stricky-header .main-menu__list>li>ul,
  .stricky-header .main-menu__list>li>ul>li>ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}


.main-menu .main-menu__list>li,
.stricky-header .main-menu__list>li {
  position: relative;
  padding-top: 32px;
  padding-bottom: 32px;
}

.main-menu .main-menu__list>li+li,
.stricky-header .main-menu__list>li+li {
  margin-left: 42px;
}

.main-menu .main-menu__list>li>a,
.stricky-header .main-menu__list>li>a {
  position: relative;
  color: #ffffff;
  font-size: 17px;
  line-height: 27px;
  font-weight: 700;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  font-family: var(--thm-font);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 1;
}

.main-menu .main-menu__list>li.current>a,
.main-menu .main-menu__list>li:hover>a,
.stricky-header .main-menu__list>li.current>a,
.stricky-header .main-menu__list>li:hover>a {
  color: var(--thm-base);
}

.main-menu .main-menu__list>li.dropdown>a,
.stricky-header .main-menu__list>li.dropdown>a {
  padding-right: 20px;
}

.main-menu .main-menu__list>li.dropdown>a:before {
  position: absolute;
  top: 1px;
  right: 2px;
  font-family: 'icomoon' !important;
  content: "\e905";
  color: #ffffff;
  font-size: 11px;
  font-weight: 700;
  transition: all 200ms linear;
  transition-delay: 0.1s;
  z-index: 1;
}

.main-menu .main-menu__list>li.current.dropdown>a:before,
.main-menu .main-menu__list>li:hover.dropdown>a:before {
  color: var(--thm-base);
  font-weight: 700;
}

.main-menu .main-menu__list>li>ul,
.main-menu .main-menu__list>li>ul>li>ul,
.stricky-header .main-menu__list>li>ul,
.stricky-header .main-menu__list>li>ul>li>ul {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 270px;
  background-color: #fff;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
  transform-origin: top;
  transform-style: preserve-3d;
  transition-delay: .1s;
  transition-timing-function: ease-in-out;
  transition-duration: .3s;
  transition-property: all;
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  z-index: 99;
}

.main-menu .main-menu__list>li>ul>li>ul>li>ul,
.stricky-header .main-menu__list>li>ul>li>ul>li>ul {
  display: none;
}

.main-menu .main-menu__list>li:hover>ul,
.main-menu .main-menu__list>li>ul>li:hover>ul,
.stricky-header .main-menu__list>li:hover>ul,
.stricky-header .main-menu__list>li>ul>li:hover>ul {
  opacity: 1;
  visibility: visible;
  transform: rotateX(0deg);
  transition: all 700ms ease;
}

.main-menu .main-menu__list>li>ul>li,
.main-menu .main-menu__list>li>ul>li>ul>li,
.stricky-header .main-menu__list>li>ul>li,
.stricky-header .main-menu__list>li>ul>li>ul>li {
  position: relative;
  width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
}

.main-menu .main-menu__list>li>ul>li+li,
.main-menu .main-menu__list>li>ul>li>ul>li+li,
.stricky-header .main-menu__list>li>ul>li+li,
.stricky-header .main-menu__list>li>ul>li>ul>li+li {
  border-top: 1px solid rgba(var(--thm-base-rgb, 53, 84, 209), 0.05);
}

.main-menu .main-menu__list>li>ul>li>a,
.main-menu .main-menu__list>li>ul>li>ul>li>a,
.stricky-header .main-menu__list>li>ul>li>a,
.stricky-header .main-menu__list>li>ul>li>ul>li>a {
  position: relative;
  display: flex;
  font-size: 15px;
  line-height: 30px;
  color: var(--thm-black);
  letter-spacing: 0;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  padding: 10px 25px;
  -webkit-transition: 500ms;
  transition: 500ms;
  font-family: var(--thm-font);
}

.main-menu .main-menu__list>li>ul>li:hover>a,
.main-menu .main-menu__list>li>ul>li>ul>li:hover>a,
.stricky-header .main-menu__list>li>ul>li:hover>a,
.stricky-header .main-menu__list>li>ul>li>ul>li:hover>a,
.main-menu .main-menu__list>li>ul>li.current a {
  color: #ffffff;
  background-color: var(--thm-base);
}

.main-menu .main-menu__list>li>ul>li>ul,
.stricky-header .main-menu__list>li>ul>li>ul {
  top: 0;
  left: 100%;
}

.main-menu .main-menu__list li ul li>ul.right-align,
.stricky-header .main-menu__list li ul li>ul.right-align {
  top: 0;
  left: auto;
  right: 100%;
}


/*-------------------------------------
    Stricky Header Css
  ---------------------------------------*/
.stricky-header {
  position: fixed;
  z-index: 991;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  visibility: hidden;
  -webkit-transform: translateY(-120%);
  transform: translateY(-120%);
  -webkit-transition: visibility 500ms ease, -webkit-transform 500ms ease;
  transition: visibility 500ms ease, -webkit-transform 500ms ease;
  transition: transform 500ms ease, visibility 500ms ease;
  transition: transform 500ms ease, visibility 500ms ease, -webkit-transform 500ms ease;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
}

@media (max-width: 1199px) {
  .stricky-header {
    display: none !important;
  }
}

.stricky-header.stricky-fixed {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  visibility: visible;
}


.main-menu .mobile-nav__toggler {
  position: relative;
  display: inline-block;
  color: #ffffff;
  font-size: 25px;
  cursor: pointer;
  line-height: 0;
  -webkit-transition: 500ms;
  transition: 500ms;
}

.main-menu .mobile-nav__toggler:hover {
  color: var(--thm-base);
}

@media (min-width: 1200px) {
  .main-menu .mobile-nav__toggler {
    display: none;
  }
}

.stricky-header.main-menu {
  background-color: #ffffff;
}

.stricky-header.main-menu .main-header-two__bottom-right{
  padding-right: 0px;
}

.stricky-header .main-menu-box {
  display: block;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.stricky-header.main-menu .logo-box-one {
  margin-top: 0px;
  padding-bottom: 0px;
}

.stricky-header .main-menu__wrapper {
  background-color: #ffffff;
}

.stricky-header .main-menu__wrapper-inner {
  justify-content: space-between;
}

.stricky-header .phone-number-box-style1 {
  display: none;
}

.stricky-header .main-menu__list>li {
  padding-top: 35px;
  padding-bottom: 35px;
}

.stricky-header .main-menu__list>li+li {
  margin-left: 30px;
}

.stricky-header .main-menu-style1-right {
  margin-left: 30px;
}


/***
=============================================
Main Header Two
=============================================
***/
.main-header-two {
  position: relative;
  display: block;
}

.main-header-two__top {
  position: relative;
  display: block;
  background: var(--thm-base);
  padding: 10px 0px 10px;
}

.main-header-two__top .auto-container {
  max-width: 1670px;
  width: 100%;
}

.main-header-two__top-inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding-left: 305px; */
}

.main-header-two__top-left {
  position: relative;
  display: block;
}

.main-header-two__contact-info {
  position: relative;
  display: block;
  line-height: 0;
}

.main-header-two__contact-info li {
  position: relative;
  display: inline-block;
  margin-right: 60px;
}

.main-header-two__contact-info li:last-child {
  margin-right: 0;
}

.main-header-two__contact-info li .inner {
  position: relative;
  display: flex;
  align-items: center;
}

.main-header-two__contact-info li .inner .icon-box {
  position: relative;
  display: block;
}

.main-header-two__contact-info li .inner .icon-box span::before {
  position: relative;
  display: inline-block;
  color: #ffffff;
  font-size: 15px;
  top: -2px;
}

.main-header-two__contact-info li .inner .text-box {
  position: relative;
  display: block;
  margin-left: 15px;
}

.main-header-two__contact-info li .inner .text-box p {
  color: #ffffff;
  font-size: 15px;
  line-height: 25px;
  font-weight: 500;
  letter-spacing: 0.025em;
}

.main-header-two__contact-info li .inner .text-box a {
  color: #ffffff;
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.main-header-two__contact-info li .inner .text-box a:hover {
  color: var(--thm-base2);
}


.main-header-two__top-right {
  position: relative;
  display: flex;
  align-items: center;
}

.main-header-two__top-right .number {
  position: relative;
  display: block;
  margin-right: 60px;
}

.main-header-two__top-right .number p {
  font-size: 17px;
  line-height: 27px;
  font-weight: 600;
}

.main-header-two__top-right .number p span::before {
  position: relative;
  display: inline-block;
  color: #ffffff;
  font-size: 16px;
  top: 1px;
  margin-right: 5px;
}

.main-header-two__top-right .number p a {
  color: #ffffff;
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.main-header-two__top-right .number p a:hover {
  color: var(--thm-black);
}

.main-header-two__top-right .btn-box {
  position: relative;
  display: block;
}

.main-header-two__top-right .btn-box a {
  position: relative;
  color: #ffffff;
  font-size: 15px;
  line-height: 25px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  padding: 0px 30px 0px;
  transition: all 200ms linear;
  transition-delay: 0.1s;
  z-index: 1;
}

.main-header-two__top-right .btn-box a::before {
  position: absolute;
  top: -22px;
  left: 0;
  bottom: -19px;
  right: 0;
  background: rgba(255, 255, 255, .2);
  content: "";
  transition: all 200ms linear;
  transition-delay: 0.1s;
  z-index: -1;
}

.main-header-two__top-right .btn-box a:hover:before {
  background: rgba(255, 255, 255, 1.0);
}

.main-header-two__top-right .btn-box a:hover {
  color: var(--thm-base);
}


.main-header-two__bottom {
  position: relative;
  display: block;
  background: #e67817;
}

.main-header-two__bottom::before {
  position: absolute;
  top: -25px;
  left: 0;
  bottom: 0;
  width: 410px;
  background: #fefbfa;
  clip-path: polygon(0 0, 79% 0, 100% 100%, 0% 100%);
  content: "";
}

.main-header-two__bottom-inner {
  position: relative;
  display: block;
}

.main-header-two__bottom .auto-container {
  max-width: 1670px;
  width: 100%;
}

.main-header-two__bottom .main-menu__wrapper-inner {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.main-header-two__bottom .main-menu .main-menu__list>li>a {
  color: #ffffff;
}

.main-header-two__bottom .main-menu .main-menu__list>li.current>a,
.main-header-two__bottom .main-menu .main-menu__list>li:hover>a {
  color: var(--thm-base);
}

.main-header-two__bottom-middle {
  position: relative;
  display: block;
}

.main-header-two__bottom .main-menu .main-menu__list>li.dropdown>a:before {
  display: block;
}

.main-header-two__bottom .main-menu .main-menu__list>li.dropdown>a,
.main-header-two__bottom .stricky-header .main-menu__list>li.dropdown>a {
  padding-right: 20px;
}

.main-header-two__bottom .main-menu .main-menu__list>li+li,
.main-header-two__bottom .stricky-header .main-menu__list>li+li {
  margin-left: 54px;
}


.main-header-two__bottom .main-menu .main-menu__list>li>a .line,
.main-header-two__bottom .stricky-header .main-menu__list>li>a .line {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -32px;
  width: 2px;
  height: 0px;
  background: #ffffff;
  margin: 0px auto 0px;
  transition: all 0.4s ease-in-out 0.3s;
}

.main-header-two__bottom .main-menu .main-menu__list>li>a:hover .line,
.main-header-two__bottom .stricky-header .main-menu__list>li>a:hover .line,
.main-header-two__bottom .main-menu .main-menu__list>li.current>a .line,
.main-header-two__bottom .stricky-header .main-menu__list>li.current>a .line {
  transition: all 0.4s ease-in-out 0.3s;
  height: 20px;
}

.main-header-two__bottom .main-menu .main-menu__list>li>a .line::before,
.main-header-two__bottom .stricky-header .main-menu__list>li>a .line::before {
  position: absolute;
  top: 6px;
  left: -5px;
  bottom: 0;
  width: 2px;
  background: var(--thm-black);
  content: "";
}

.main-header-two__bottom .main-menu .main-menu__list>li>a .line::after,
.main-header-two__bottom .stricky-header .main-menu__list>li>a .line::after {
  position: absolute;
  top: 6px;
  right: -5px;
  bottom: 0;
  width: 2px;
  background: var(--thm-black);
  content: "";
}

.main-header-two__bottom-right {
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 235px;
}

.main-header-two__bottom .main-header-one__bottom-right-btn a:hover {
  color: #ffffff;
  background: var(--thm-black);
}

.main-header-two__bottom-right .header-search-box::before {
  position: absolute;
  top: -6px;
  left: 0;
  bottom: -5px;
  width: 1px;
  background: var(--thm-black);
  content: "";
}

.main-header-two__bottom-right .header-search-box a {
  color: var(--thm-black);
  font-weight: 700;
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.main-header-two__bottom-right .header-search-box a:hover {
  color: var(--thm-base)
}

.header-social-links-style2 {
  position: relative;
  display: block;
  margin-left: 40px;
}

.header-social-links-style2 li {
  position: relative;
  display: inline-block;
  margin-right: 5px;
}

.header-social-links-style2 li:last-child {
  margin-right: 0;
}

.header-social-links-style2 li a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  background: #f2eeeb;
  border-radius: 50%;
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.header-social-links-style2 li a span::before {
  position: relative;
  display: inline-block;
  color: var(--thm-base);
  font-size: 18px;
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.header-social-links-style2 li a:hover {
  background: var(--thm-base2);
}

.header-social-links-style2 li a:hover span::before {
  color: #ffffff;
}

.stricky-header--two .header-social-links-style2 {
  display: none;
}

.stricky-header--two.stricky-header .main-menu__list>li>a {
  color: var(--thm-black);
}

.stricky-header--two.stricky-header .main-menu__list>li.current>a,
.stricky-header--two.stricky-header .main-menu__list>li:hover>a {
  color: var(--thm-base2);
}

.stricky-header--two.stricky-header .main-menu__list>li.dropdown>a:before {
  color: var(--thm-black);
}

.stricky-header--two.stricky-header .main-menu__list>li.current>a::before,
.stricky-header--two.stricky-header .main-menu__list>li:hover>a::before {
  color: var(--thm-base2);
}

/*** 

====================================================================
	Main Slider One new
====================================================================

***/

.main-slider {
  position: relative;
  z-index: 10;
  overflow: hidden;
}

.main-slider .tp-caption {
  z-index: 5;
}

.main-slider .tp-dottedoverlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, .20);
}

.main-slider h5 {
  font-size: 24px;
  color: #ffffff;
  font-weight: 700;
  line-height: 1.2em;
}

.main-slider h4 {
  font-size: 48px;
  color: #ffffff;
  font-weight: 700;
  line-height: 1.2em;
}

.main-slider h2 {
  font-size: 60px;
  line-height: 1.2em;
  color: #ffffff;
  font-weight: 700;
  text-transform: uppercase;
}

.main-slider .text {
  position: relative;
  font-weight: 400;
  font-size: 18px;
  line-height: 36px;
  color: #ffffff;
}

.main-slider .theme-btn {
  display: inline-block;
  padding: 18px 40px;
  margin: 0 15px;
}

.main-slider .tp-bannertimer,
.main-slider .tp-bullets {
  display: none !important;
}

.main-slider.style-two h2 {
  font-size: 65px;
}

.arrow-down {
  position: absolute;
  left: 50%;
  bottom: 65px;
  height: 40px;
  width: 40px;
  margin-left: -20px;
  line-height: 40px;
  animation: fadeInDown 3s infinite;
}

.arrow-down .icon {
  font-size: 36px;
  color: #ffffff;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
}


/***
=============================================
  Page Header
=============================================
***/
.page-header {
  position: relative;
  display: block;
  padding: 100px 0px 100px;
  z-index: 1;
}

.page-header__bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-attachment: scroll;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: -1;
}

.banner-img {
  background-image: url('../images/banner/1.webp');
}

.banner-img2 {
  background-image: url('../images/banner/2.webp');
}

.banner-img3 {
  background-image: url('../images/banner/3.webp');
}

.banner-img4 {
  background-image: url('../images/banner/4.webp');
}

.banner-img5 {
  background-image: url('../images/banner/5.webp');
}

.banner-img6 {
  background-image: url('../images/banner/6.webp');
}

.banner-img7 {
  background-image: url('../images/banner/7.webp');
}

.banner-img8 {
  background-image: url('../images/banner/8.webp');
}


.page-header__bg::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000001;
  opacity: 0.5;
  content: "";
  z-index: -2;
}

.page-header__inner {
  position: relative;
  display: block;
  text-align: center;
}

.page-header__inner h1 {
  color: #ffffff;
  font-size: 42px;
  line-height: 1em;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 19px;
}

.page-header__inner .thm-breadcrumb {
  position: relative;
  display: block;
}

.page-header__inner .thm-breadcrumb li {
  position: relative;
  display: inline-block;
  color: #ffffff;
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  text-transform: capitalize;
}

.page-header__inner .thm-breadcrumb li span::before {
  position: relative;
  display: inline-block;
  color: #ffffff;
  font-size: 18px;
  top: 2px;
}

.page-header__inner .thm-breadcrumb li a {
  color: #ffffff;
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  text-transform: capitalize;
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.page-header__inner .thm-breadcrumb li a:hover {
  color: var(--thm-base);
}


/***
=============================================
Services One
=============================================
***/
.services-one {
  position: relative;
  display: block;
  background: #ffffff;
  padding: 0px 0px 90px;
  z-index: 1;
}

.services-one .auto-container {
  max-width: 1360px;
  width: 100%;
}

.services-one__single {
  position: relative;
  display: block;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 10px 60px 0px rgb(0 0 0 / 10%);
  padding: 40px 20px 40px;
  margin-bottom: 30px;
  z-index: 1;
}

.service-height {
  height: 624px;
}

.services-one__single-img {
  position: relative;
  display: block;
}

.services-one__single-img .inner {
  position: relative;
  display: block;
  width: 200px;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto;
}

.services-one__single-img .inner:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition-delay: .1s;
  transition-timing-function: ease-in-out;
  transition-duration: .7s;
  transition-property: all;
  background: rgba(var(--thm-black-rgb), 0.6);
  opacity: 0;
  z-index: 1;
  content: "";
}

.services-one__single:hover .services-one__single-img .inner::before {
  opacity: 1;
}

.services-one__single-img .inner img {
  width: 100%;
  transition: .5s ease;
  transform: scale(1.05);
}

.services-one__single:hover .services-one__single-img .inner img {
  transform: scale(1);
}

.services-one__single-img .icon-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background: var(--thm-base);
  margin: -50px auto 0px;
  z-index: 2;
}

.services-one__single-img .icon-box span::before {
  position: relative;
  display: inline-block;
  color: #ffffff;
  font-size: 45px;
}

.services-one__single-title {
  position: relative;
  display: block;
  margin-top: 25px;
}

.services-one__single-title h2 {
  font-size: 30px;
  line-height: 40px;
  font-weight: 600;
  text-transform: capitalize;
  color: var(--thm-black);
}

.services-one__single-title h2 a {
  color: var(--thm-black);
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.services-one__single-title h2 a:hover {
  color: var(--thm-base);
}


/***
=============================================
Brand One
=============================================
***/
.brand-one {
  position: relative;
  display: block;
  padding: 70px 0 70px;
  z-index: 2;
  background: #eff0f0;
}

.brand-one::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0px;
  bottom: 0;
  background: var(--thm-base);
  border-bottom-right-radius: 60px;
  border-bottom-left-radius: 60px;
  content: "";
  z-index: -1;
}
.brand-about::before{
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.brand-one .swiper-slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  /* border: 1px solid rgba(255, 255, 255, 0.8); */
  background-color: #ffffff;
  height: 145px;
  transition: .5s ease;
}

.brand-one .swiper-slide:hover {
  border-color: #ffffff;
}

.brand-one .swiper-slide img {
  transition: .5s ease;
  transform: scale(1.02);
  /* opacity: 0.8; */
 
    filter: none;
    -webkit-filter: grayscale(0);
  width: auto;
}

.brand-one .swiper-slide:hover img {
  /* opacity: 1; */
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  transform: scale(1);
}


/***
=============================================
Portfolio One
=============================================
***/
.portfolio-one {
  position: relative;
  display: block;
  background: #eff0f0;
  padding: 240px 0px 120px;
  z-index: 1;
  margin-top: -120px;
}

.portfolio-one__bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-position: center center;
  background-attachment: scroll;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.17;
  z-index: -1;
}

.portfolio-one__top {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 46px;
}

.portfolio-one__top .sec-title {
  padding-bottom: 0;
}

.portfolio-one__top .btn-box {
  position: relative;
  display: block;
  line-height: 0;
}

.portfolio-one__inner {
  position: relative;
  display: block;
}

.portfolio-one__tab-box {
  position: relative;
  display: flex;
  align-items: flex-start;
}

.portfolio-one__tab-menu {
  position: relative;
  display: block;
  padding: 38px 50px 41px;
  max-width: 400px;
  width: 100%;
  z-index: 1;
}

.portfolio-one__tab-menu .title {
  position: relative;
  display: block;
  margin-bottom: 17px;
}

.portfolio-one__tab-menu .title h2 {
  color: #ffffff;
  font-size: 55px;
  line-height: 65px;
  font-weight: 700;
  text-transform: capitalize;
}

.portfolio-one__tab-menu-bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-attachment: scroll;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: -1;
}

.portfolio-one__tab-menu-bg::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.72);
  content: "";
  z-index: -1;
}


.portfolio-one__tab-menu .tab-buttons {
  position: relative;
  display: block;
}

.portfolio-one__tab-menu .tab-buttons .tab-btn {
  position: relative;
  display: block;
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  background-color: transparent;
  padding: 16px 0px 16px;
  border-bottom: 1px solid rgba(255, 255, 255, .6);
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: var(--thm-font);
  transition: all 200ms linear;
  transition-delay: 0.1s;
  z-index: 1;
}

.portfolio-one__tab-menu .tab-buttons .tab-btn:last-child {
  border-bottom: none;
  padding-bottom: 0px;
}

.portfolio-one__tab-menu .tab-buttons .tab-btn span::before {
  position: relative;
  display: inline-block;
  float: right;
  color: #ffffff;
  font-size: 16px;
  top: 5px;
}

.portfolio-one__inner .tabs-content {
  position: relative;
  display: block;
  max-width: 800px;
  width: 100%;
  margin-top: 45px;
  margin-left: -30px;
  z-index: 5;
}

.portfolio-one__inner .tabs-content .tab {
  position: relative;
  display: none;
  -webkit-transform: translateY(35px);
  -ms-transform: translateY(35px);
  transform: translateY(35px);
  -webkit-transition: all 1000ms ease;
  -moz-transition: all 1000ms ease;
  -ms-transition: all 1000ms ease;
  -o-transition: all 1000ms ease;
  transition: all 1000ms ease;
  z-index: 10;
}

.portfolio-one__inner .tabs-content .tab.active-tab {
  display: block;
  margin-top: 0px;
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
}


.tabs-content__inner {
  position: relative;
  display: block;
}

.tabs-content__img {
  position: relative;
  display: block;
}

.tabs-content__img .inner {
  position: relative;
  display: block;
  overflow: hidden;
}

.tabs-content__img .inner img {
  width: 100%;
}

.tabs-content__img .content-box {
  position: absolute;
  left: 20px;
  bottom: -1px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 20px 20px;
}

.tabs-content__img .content-box::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #ffffff;
  box-shadow: 5px 8.66px 20px 0px rgb(130, 132, 134, 0.08);
  clip-path: polygon(0 0, 100% 21%, 100% 100%, 0% 100%);
  content: "";
}

.tabs-content__img .content-box .text-box {
  position: relative;
  display: block;
}

.tabs-content__img .content-box .text-box h2 {
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
  /* text-transform: capitalize; */
  letter-spacing: -0.01em;
}

.tabs-content__img .content-box .text-box h2 a {
  color: var(--thm-base2);
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.tabs-content__img .content-box .text-box h2 a:hover {
  color: var(--thm-base);
}

.tabs-content__img .content-box .text-box p {
  font-weight: 500;
  margin: 0px;
}

.tabs-content__img .content-box .btn-box {
  position: relative;
  display: block;
}

.tabs-content__img .content-box .btn-box a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background: var(--thm-base);
  border-radius: 50%;
  transition: all 200ms linear;
  transition-delay: 0.1s;

}

.tabs-content__img .content-box .btn-box a:hover {
  background: var(--thm-black);
}

.tabs-content__img .content-box .btn-box a span::before {
  position: relative;
  display: inline-block;
  color: #ffffff;
  font-size: 18px;
}

/***
=============================================
pdf banner
=============================================
***/

.cta-one {
  background: var(--thm-base);
  display: block;
  padding: 70px 0;
  position: relative;
  z-index: 1
}

.cta-one .shape1 {
  border-bottom: 40px solid #151518;
  border-left: 15px solid transparent;
  border-right: 25px solid transparent;
  content: "";
  left: 485px;
  position: absolute;
  top: -40px
}

.cta-one__bg {
  background-attachment: scroll;
  background-image: url(../images/backgrounds/pdf-bg.jpg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  -webkit-clip-path: polygon(0 0,100% 0,80% 100%,0 100%);
  clip-path: polygon(0 0,100% 0,80% 100%,0 100%);
  left: 0;
  position: absolute;
  top: -40px;
  width: 500px;
  z-index: -1
}

.cta-one__inner {
  display: block;
  position: relative
}

.cta-one__content {
  align-items: center;
  display: flex;
  float: right;
  justify-content: space-between;
  max-width: 1000px;
  position: relative;
  width: 100%
}

.cta-one__content .text-box {
  display: block;
  position: relative
}

.cta-one__content .text-box p {
  color: #fff;
  letter-spacing: .2em
}

.cta-one__content .text-box h2 {
  color: #fff;
  font-size: 32px;
  font-weight: 600;
  line-height: 50px;
  margin-top: 1px;
  text-transform: capitalize
}

.cta-one__content .btn-box {
  display: block;
  line-height: 0;
  position: relative
}

.cta-one__content .btn-box .thm-btn {
  background: var(--thm-base2)
}

.cta-one__content .btn-box .thm-btn:after,.cta-one__content .btn-box .thm-btn:before {
  background: var(--thm-black)
}


/***
=============================================
Footer One
=============================================
***/
.footer-one {
  position: relative;
  display: block;
  padding: 60px 0px 0px;
  z-index: 1;
}

.footer-one__bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.08;
  background-attachment: scroll;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: -1;
  background-image: url('../images/backgrounds/footer3.webp');
}

.footer-one__pattern {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-attachment: scroll;
  background-repeat: repeat;
  background-position: center center;
  z-index: -1;
  background-image: url('../images/backgrounds/thm-pattern.png');
}

.footer-one::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000000;
  z-index: -2;
  content: "";
}

.footer {
  position: relative;
  display: block;
  padding-bottom: 50px;
}

.footer-widget__single {
  position: relative;
  display: block;
}

.footer-widget__single.ml30 {
  margin-left: 18px;
}

.footer-widget__single .title {
  position: relative;
  display: block;
  margin-bottom: 20px;
}

.footer-widget__single .title h2 {
  color: #ffffff;
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
}

.footer-widget__single-about {
  position: relative;
  display: block;
}

.footer-widget__single-about .logo-box {
  position: relative;
  display: block;
  margin-bottom: 25px;
}

.footer-widget__single-about .logo-box img{
  -webkit-filter: brightness(0) invert(1);
 filter: brightness(0) invert(1);
}

.footer-widget__single-about .logo-box a {
  position: relative;
  display: inline-block;
}

.footer-widget__single-about .text-box {
  position: relative;
  display: block;
  margin-bottom: 31px;
}

.footer-widget__single-about .text-box p {
  color: #ffffff;
}

.footer-widget__single-about .social-link {
  position: relative;
  display: block;
  border-top: 1px solid #ffffff;
  padding-top: 20px;
}

.footer-widget__single-about .social-link li {
  position: relative;
  display: inline-block;
  margin-right: 6px;
}

.footer-widget__single-about .social-link li a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #17171a;
}

.footer-widget__single-about .social-link li a::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  background: var(--thm-base);
  transform: scale(0.5);
  opacity: 0;
  transition: all 300ms linear;
  transition-delay: 0.1s;
  content: "";
}

.footer-widget__single-about .social-link li a:hover::before {
  transform: scale(1);
  opacity: 1;
}

.footer-widget__single-about .social-link li a span::before {
  position: relative;
  display: inline-block;
  color: #ffffff;
  font-size: 18px;
  transition: all 300ms linear;
  transition-delay: 0.1s;
}

.footer-widget__single-about .social-link li a:hover span::before {
  color: #ffffff;
}

.footer-widget__single-explore {
  position: relative;
  display: block;
}

.footer-widget__single-list {
  position: relative;
  display: block;
}

.footer-widget__single-list li {
  position: relative;
  display: block;
  margin-bottom: 10px;
}

.footer-widget__single-list li:last-child {
  margin-bottom: 0;
}

.footer-widget__single-list li a {
  position: relative;
  display: block;
  color: rgba(255, 255, 255, .6);
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.footer-widget__single-list li {
  position: relative;
  display: block;
  color: #ffffff;
  font-size: 18px;
  line-height: 25px;
  font-weight: 400;
  transition: all 200ms linear;
  transition-delay: 0.1s;
  padding-left: 20px;
  width: 50%;
  float: left;
}

.footer-widget__single-list li a {
  padding-left: 20px;
}

.footer-widget__single-list li a:hover {
  padding-left: 20px;
}

.footer-widget__single-list li a::before {
  position: absolute;
  top: 11px;
  left: 0;
  width: 6px;
  height: 6px;
  background: var(--thm-base);
  border-radius: 50%;
  transform: scale(1);
  opacity: 1;
  content: "";
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.footer-widget__single-list li::before {
  position: absolute;
  top: 11px;
  left: 0;
  width: 6px;
  height: 6px;
  background: #ffffff;
  border-radius: 50%;
  transform: scale(1);
  opacity: 1;
  content: "";
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.footer-widget__single-list li a:hover::before {
  opacity: 1;
  transform: scale(1);
}

.footer-widget__single-list li a:hover {
  color: #ffffff;
}

.footer-widget__single-contact {
  position: relative;
  display: block;
}

.footer-widget__single-contact .text {
  color: #ffffff;
}

.info-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
}

.info-box:last-child {
  margin-bottom: 0px;
}

.info-box_icon {
  width: 30px;
  height: 30px;
  line-height: 40px;
  min-width: 30px;
  font-size: 18px;
  color: #ffffff;
  border-radius: 999px;
  text-align: center;
  margin-right: 10px;
}

.footer-widget__single-contact .number {
  margin: 0;
}

.footer-widget__single-contact .number a {
  color: #ffffff;
  transition: all 200ms linear;
  transition-delay: 0.1s;
  display: block;
}

.footer-widget__single-contact .number a:hover {
  color: var(--thm-base2);
}

.footer-widget__single-contact .email {
  margin: 0;
}

.footer-widget__single-contact .email a {
  color: #ffffff;
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.footer-widget__single-contact .email a:hover {
  color: var(--thm-base2);
}

.footer-widget__single-newsletter {
  position: relative;
  display: block;
  background: #17171a;
  padding: 48px 50px 44px;
  border-radius: 10px;
  margin-top: 2px;
}

.footer-widget__single-newsletter .title {
  position: relative;
  display: block;
  margin-bottom: 18px;
}

.footer-widget__single-newsletter .title h3 {
  color: #ffffff;
  font-size: 24px;
  line-height: 34px;
  font-weight: 400;
}

.footer-widget__single-newsletter-form {
  position: relative;
  display: block;
}

.footer-widget__single-newsletter-form .input-box {
  position: relative;
  display: block;
}

.footer-widget__single-newsletter-form .input-box:before {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  content: "";
  height: 5px;
  background-color: rgba(255, 255, 255, 0.10);
  border-radius: 3px;
}

.footer-widget__single-newsletter-form .input-box input[type="email"] {
  font-size: 18px;
  color: rgba(255, 255, 255, .6);
  font-weight: 400;
  height: 60px;
  width: 100%;
  background: transparent;
  border: none;
  border-radius: 0;
  padding-right: 40px;
  outline: none;
}

.footer-widget__newsletter-btn {
  position: absolute;
  top: 50%;
  right: 0;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: 50%;
  background: transparent;
  border: 0;
  transform: translateY(-50%);
  transition: all 500ms ease;
  padding: 0;
}

.footer-widget__newsletter-btn i {
  font-size: 16px;
  color: #ffffff;
  transition: all 500ms ease;
}

.footer-widget__newsletter-btn:hover i {
  color: var(--thm-base);
}

.checked-box {
  position: relative;
  display: block;
  min-height: 30px;
  margin-top: 23px;
}

.checked-box label {
  position: relative;
  display: inline-block;
  padding-left: 30px;
  margin-right: 0px;
  margin-bottom: 0;
  color: rgba(255, 255, 255, .6);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  cursor: pointer;
  min-height: 30px;
  font-family: var(--thm-font);
}

.checked-box input[type="checkbox"] {
  display: none;
}

.checked-box input[type="checkbox"]+label span {
  position: absolute;
  top: 4px;
  left: 0;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, .6);
  border-radius: 50%;
  cursor: pointer;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.checked-box label span:before {
  position: absolute;
  top: 4px;
  left: 5px;
  display: block;
  border-bottom: 2px solid rgba(255, 255, 255, .6);
  border-right: 2px solid rgba(255, 255, 255, .6);
  content: '';
  width: 5px;
  height: 9px;
  pointer-events: none;
  -webkit-transform-origin: 66% 66%;
  -ms-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  opacity: 0;
}

.checked-box input[type="checkbox"]:checked+label span {
  border-color: rgba(255, 255, 255, .6);
}

.checked-box input[type="checkbox"]:checked+label span:before {
  opacity: 1;
}

.footer-one__bottom {
  position: relative;
  display: block;
}

.footer-one__bottom .bottom-inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #ffffff;
  padding: 10px 0px 10px;
}

.footer-one__bottom .bottom-inner .copyright {
  position: relative;
  display: block;
}

.footer-one__bottom .bottom-inner .copyright p {
  color: #ffffff;
  margin: 0;
}
.footer-one__bottom .bottom-inner .copyright p a{
  color: #ffffff;
}

.footer-one__bottom .bottom-inner .copyright p a:hover{
  color: var(--thm-base2);
}

.footer-one__bottom-menu {
  position: relative;
  display: block;
}

.footer-one__bottom-menu li {
  position: relative;
  display: inline-block;
  padding-right: 10px;
  margin-right: 5px;
}

.footer-one__bottom-menu li::before {
  position: absolute;
  top: 8px;
  bottom: 8px;
  right: 0;
  width: 1px;
  background: rgba(255, 255, 255, 0.6);
  content: "";
}

.footer-one__bottom-menu li:last-child:before {
  display: none;
}

.footer-one__bottom-menu li a {
  color: rgba(255, 255, 255, 0.6);
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.footer-one__bottom-menu li a:hover {
  color: var(--thm-base);
}


/***
=============================================
Feature Four
=============================================
***/
.feature-four {
  position: relative;
  display: block;
}

.feature-four .auto-container {
  max-width: 1670px;
  width: 100%;
}

.feature-four__inner_home {
  position: relative;
  display: block;
  background: transparent;
  padding: 70px 0px 50px;
  margin-top: 0px;
  z-index: 99;

}

.feature-four__inner {
  position: relative;
  display: block;
  background: transparent;
  padding: 120px 0px 50px;
  margin-top: -160px;
  z-index: 99;
}

.feature-four__bg {
  position: relative;
  top: 0;
  left: 0;
  bottom: 70px;
  right: 0;
  background-attachment: scroll;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* z-index: -1; */
  background-image: url(../images/backgrounds/feature-bg.webp);
}

.feature-four__single {
  position: relative;
  display: block;
  background: #ffffff;
  box-shadow: 0px 10px 30px 0px rgb(0, 0, 0, 0.05);
  padding: 0px 35px 35px;
  margin-bottom: 30px;
}

.mission-height {
  height: 486px;
}

.feature-four__single .icon-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 115px;
  background: #f6f4f9;
  border-radius: 10px;
  margin-bottom: 23px;
  z-index: 1;
}

.feature-four__single .icon-box:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background: var(--thm-base);
  transition-delay: 0.5s;
  transition: .5s;
  border-radius: 10px;
  opacity: 1;
  -webkit-transform: perspective(400px) rotateX(-90deg) scale(0.2);
  -ms-transform: perspective(400px) rotateX(-90deg) scale(0.2);
  transform: perspective(400px) rotateX(-90deg) scale(0.2);
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
  z-index: -1;
}

.feature-four__single:hover .icon-box:before {
  opacity: 1;
  -webkit-transform: perspective(400px) rotateX(0deg) scale(1.0);
  -ms-transform: perspective(400px) rotateX(0deg) scale(1.0);
  transform: perspective(400px) rotateX(0deg) scale(1.0);
}

.feature-four__single .icon-box span:before {
  position: relative;
  display: inline-block;
  color: var(--thm-base);
  font-size: 60px;
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.feature-four__single:hover .icon-box span:before {
  color: #ffffff;
}

.feature-four__single h2 {
  font-size: 22px;
  line-height: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.feature-four__single h2 a {
  color: var(--thm-black);
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.feature-four__single h2 a:hover {
  color: var(--thm-base);
}

.feature-four__single p {
  margin: 0;
}

.feature-four__single .btn-box {
  position: relative;
  display: block;
  margin-top: 27px;
}

.feature-four__single .btn-box a {
  position: relative;
  display: inline-block;
  color: var(--thm-black);
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.feature-four__single .btn-box a:hover {
  color: var(--thm-base);
}

.feature-four__single .btn-box a span::before {
  position: relative;
  display: inline-block;
  font-size: 30px;
}

.feature-four__right-content {
  position: relative;
  display: block;
  margin-right: -140px;
  padding-left: 60px;
  padding-bottom: 104px;
  padding-top: 104px;
  z-index: 1;
}

.feature-four__right-content::before {
  position: absolute;
  top: -1px;
  left: 0;
  bottom: 0;
  right: 0;
  background: var(--thm-base);
  content: "";
  z-index: -1;
}

.feature-four__right-content h2 {
  position: relative;
  display: inline-block;
  color: #ffffff;
  font-size: 80px;
  line-height: 1.1em;
  font-weight: 600;
  text-transform: capitalize;
}

.feature-four__right-content h2::before {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 4px;
  background: #ffffff;
  content: "";
}

.feature-four__right-content h2 span {
  position: relative;
  display: inline-block;
}

.feature-four__right-content h2 .plus {
  position: relative;
  display: inline-block;
  top: 6px;
}

.feature-four__right-content .odometer.odometer-auto-theme,
.feature-four__right-content .odometer.odometer-theme-default {
  font-family: var(--thm-font);
  line-height: 1.1em;
}

/***
=============================================
About Two
=============================================
***/
.about-two {
  position: relative;
  display: block;
  background: #ffffff;
  padding: 70px 0px 50px;
  z-index: 1;
}


.about-two .shape4 {
  bottom: 120px;
  opacity: .1;
  position: absolute;
  right: -40px;
  z-index: -1
}

.about-two__content {
  position: relative;
  display: block;
  margin-left: -40px;
  margin-right: 25px;
}

.about-two__content .sec-title-two {
  padding-bottom: 15px;
}

.about-two__content .text-box1 {
  position: relative;
  display: block;
}

.about-two__content .text-box1 p {
  margin: 0px;
  margin-bottom: 10px;
  text-align: justify;
}

.about-one__content-text2 {
  position: relative;
  display: block;
  margin-top: 18px;
}

.about-one__content-text2-single {
  position: relative;
  display: block;
  margin-bottom: 0px;
}

.about-one__content-text2-single ul {
  position: relative;
  display: block;
}

.about-one__content-text2-single ul li {
  position: relative;
  display: block;
  padding-left: 40px;
}

.about-one__content-text2-single ul li .icon-box {
  position: absolute;
  top: 0;
  left: 0;
}

.about-one__content-text2-single ul li .icon-box span:before {
  position: relative;
  display: inline-block;
  color: var(--thm-base);
  font-size: 25px;
  top: 3px;
}

.about-one__content-text2-single ul li .text-box {
  position: relative;
  display: block;
}

.about-one__content-text2-single ul li .text-box p {
  color: var(--thm-black);
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
}

.about-one__content-text2-single .text {
  position: relative;
  display: block;
  margin-top: 10px;
}

.about-one__content-text2-single .text p {
  margin: 0;
  text-align: justify;
}

.about-two__content-list {
  position: relative;
  display: block;
  padding-left: 20px;
  margin-top: 32px;
}

.about-two__content-list::before {
  position: absolute;
  top: 4px;
  left: 0px;
  bottom: 4px;
  width: 2px;
  background: var(--thm-base);
  content: "";
}

.about-two__content-list li {
  position: relative;
  display: block;
  margin-bottom: 12px;
}

.about-two__content-list li:last-child {
  margin-bottom: 0;
}

.about-two__content-list li .inner {
  position: relative;
  display: block;
  padding-left: 40px;
}

.about-two__content-list li .inner .icon-box {
  position: absolute;
  top: 4px;
  left: 0;
  width: 20px;
  height: 20px;
  background: var(--thm-base);
  border-radius: 50%;
  text-align: center;
}

.about-two__content-list li .inner .icon-box span {
  position: relative;
  display: block;
  line-height: 0;
}

.about-two__content-list li .inner .icon-box span:before {
  position: relative;
  display: inline-block;
  color: #ffffff;
  font-size: 12px;
  line-height: 20px;
}

.about-two__content-list li .inner .text-box {
  position: relative;
  display: block;
}

.about-two__content-list li .inner .text-box p {
  color: var(--thm-black);
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
}

.about-two__progress {
  position: relative;
  display: block;
  margin-top: 43px;
}

.about-two__progress-single {
  position: relative;
  display: block;
  margin-bottom: 22px;
}

.about-two__progress-single-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-transform: uppercase;
  margin-bottom: 12px;
}

.about-two__progress .bar {
  position: relative;
  width: 100%;
  height: 15px;
  background: #f1f3f2;
  margin-bottom: 6px;
  border-radius: 7.5px;
}

.about-two__progress .bar-inner {
  position: relative;
  display: block;
  width: 0px;
  height: 15px;
  background: var(--thm-base);
  -webkit-transition: all 1500ms ease;
  -ms-transition: all 1500ms ease;
  -o-transition: all 1500ms ease;
  -moz-transition: all 1500ms ease;
  transition: all 1500ms ease;
  border-radius: 7.5px;
  z-index: 1;
}

.about-two__progress .count-text {
  position: absolute;
  top: -3px;
  right: 10px;
  color: #ffffff;
  font-size: 12px;
  line-height: 22px;
  font-weight: 400;
  text-align: center;
  font-family: var(--thm-font);
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  transition: all 500ms ease;
  opacity: 1;
}

.about-two__content-bottom {
  position: relative;
  /* display: flex;
  align-items: center; */
  margin-top: 30px;
}

.about-two__content-bottom .author-box {
  position: relative;
  display: flex;
  align-items: center;
}

.about-two__content-bottom .author-box .img-box {
  position: relative;
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
}

.about-two__content-bottom .author-box .img-box img {
  width: 100%;
}

.about-two__content-bottom .author-box .title-box {
  position: relative;
  display: block;
  padding-left: 25px;
  line-height: 0;
}

.about-two__content-bottom .author-box .title-box h2 {
  font-size: 22px;
  line-height: 32px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: -0.025em;
}

.about-two__content-bottom .author-box .title-box span {
  color: var(--thm-gray);
  font-size: 15px;
  line-height: 25px;
  font-weight: 600;
  text-transform: capitalize;
}

.about-two__content-bottom .btn-box {
  position: relative;
  display: block;
  margin-left: 0px;
  line-height: 0px;
}


.about-two__right {
  position: relative;
  display: block;
  margin-left: -15px;
  margin-right: -45px;
}

.about-two__right-single {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.about-two__right-single.mb30 {
  margin-top: 30px;
}

.about-two__right-single .img-box {
  position: relative;
  display: block;
  overflow: hidden;
}

.about-two__right-single .img-box::before {
  position: absolute;
  top: 0%;
  right: 0%;
  display: block;
  content: '';
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, .4);
  border-radius: 50%;
  -webkit-transform: translateX(-50%, -50%);
  transform: translateX(-50%, -50%);
  opacity: 0;
  z-index: 1;
}

.about-two__right-single:hover .img-box::before {
  -webkit-animation: circle .95s;
  animation: circle .95s;
}

.about-two__right-single .img-box img {
  width: 100%;
  transform: scale(1.0);
  transition-delay: .1s;
  transition-timing-function: ease-in-out;
  transition-duration: .7s;
  transition-property: all;
}

.about-two__right-single:hover .img-box img {
  transform: scale(1.1) rotate(1deg);
}

.about-two__right-single .img-box .video-box {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-two__right-single .img-box .video-box a {
  position: relative;
  display: inline-block;
}

.about-two__right-single-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 90px;
  line-height: 90px;
  border-radius: 50%;
  text-align: center;
  font-size: 25px;
  color: #ffffff;
  background-color: var(--thm-base);
  margin: 0 auto;
}

.about-two__right-single-icon .ripple,
.about-two__right-single-icon .ripple:before,
.about-two__right-single-icon .ripple:after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90px;
  height: 90px;
  transform: translate(-50%, -50%);
  -ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -o-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -webkit-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
  border-radius: 50%;
}

.about-two__right-single-icon .ripple:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
  content: "";
  position: absolute;
}

.about-two__right-single-icon .ripple:after {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  content: "";
  position: absolute;
}

.about-two__right-single .counter-box {
  position: relative;
  display: block;
  background: var(--thm-base);
  padding: 50px 40px 50px;
}

.about-two__right-single .counter-box h2 {
  position: relative;
  color: #ffffff;
  font-size: 50px;
  line-height: 1.1em;
  font-weight: 700;
  text-transform: capitalize;
}

.about-two__right-single .counter-box h2 .plus {
  position: absolute;
  top: -6px;
}

.about-two__right-single .counter-box .odometer.odometer-auto-theme,
.about-two__right-single .counter-box .odometer.odometer-theme-default {
  font-family: var(--thm-font);
  line-height: 1.1em;
}


/***
=============================================
Services Two
=============================================
***/
.services-two {
  position: relative;
  display: block;
  background: #eff0f0;
  padding: 70px 0px 0px;
  overflow: hidden;
  z-index: 1;
}

.services-two__img {
  position: absolute;
  top: 50px;
  left: -400px;
  z-index: -1;
}

.services-two .sec-title-two {
  margin-left: 0px;
}

.services-two__left-content {
  position: relative;
  display: block;
  margin-left: 170px;
}

.services-two__left-content-single {
  position: relative;
  display: block;
  padding-top: 50px;
  margin-bottom: 30px;
}

.services-two__left-content-single.mb30 {
  margin-top: 32px;
}

.services-two__left-content-single .icon-box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 125px;
  margin: 0px auto 0px;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 10px 60px 0px rgb(0, 0, 0, 0.07);
  z-index: 5;
}

.services-two__left-content-single .icon-box span::before {
  position: relative;
  display: inline-block;
  color: var(--thm-base);
  font-size: 65px;
  transition: all 200ms linear;
  transition-delay: 0.1s;
  z-index: 5;
}

.services-two__left-content-single:hover .icon-box span::before {
  color: #ffffff;
}

.services-two__left-content-single .icon-box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0px;
  right: 0;
  border-radius: 10px;
  background-color: var(--thm-base);
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
  transform-origin: bottom right;
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
  z-index: -1;
}

.services-two__left-content-single:hover .icon-box::before {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  transform-origin: top center;
}

.services-two__left-content-single .inner {
  position: relative;
  display: block;
  padding: 97px 70px 41px;
  background: #ffffff;
  border-radius: 9px;
  box-shadow: 0px 10px 60px 0px rgb(0, 0, 0, 0.07);
}

.services-two__left-content-single .inner h2 {
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 12px;
}

.services-two__left-content-single .inner h2 a {
  color: var(--thm-black);
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.services-two__left-content-single .inner h2 a:hover {
  color: var(--thm-base);
}

.services-two__left-content-single .inner p {
  margin: 0;
}



.services-two__right-content {
  position: relative;
  display: block;
  margin-right: -100px;
}

.services-two__right-content-single {
  position: relative;
  display: flex;
  align-items: center;
  background: #ffffff;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0.175px 9.998px 38px 2px rgb(0, 0, 0, 0.1);
  padding: 35px 35px 35px;
  margin-bottom: 15px;
}

.services-two__right-content-single::before {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 4px;
  background: #ede8e4;
  content: "";
}

.services-two__right-content-single::after {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 4px;
  background: var(--thm-base);
  transform: scaleX(0.7) rotateX(0deg);
  transition: all 0.4s linear;
  opacity: 0;
  border-radius: 0px;
  content: "";
}

.services-two__right-content-single:hover::after {
  transform: scaleX(1.0) rotateX(0deg);
  transition: all 0.4s linear;
  opacity: 1;
}

.services-two__right-content-single .icon-box {
  position: relative;
  display: block;
}

.services-two__right-content-single .icon-box span::before {
  position: relative;
  display: inline-block;
  color: var(--thm-base);
  font-size: 60px;
  transition: all 500ms linear;
  transition-delay: 0.1s;
  transform: scale(1);
}

.services-two__right-content-single:hover .icon-box span::before {
  transform: scale(0.9);
}

.services-two__right-content-single .title-box {
  position: relative;
  display: block;
  margin-left: 25px;
  flex: 1;
}

.services-two__right-content-single .title-box h2 {
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
}

.services-two__right-content-single .title-box h2 a {
  color: var(--thm-black);
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.services-two__right-content-single .title-box h2 a:hover {
  color: var(--thm-base);
}







/***
=============================================
Team Two
=============================================
***/
.team-two {
  position: relative;
  display: block;
  background: #ffffff;
  padding: 70px 0px 50px;
}

.team-two .auto-container {
  max-width: 1650px;
  width: 100%;
}

.team-two__single {
  position: relative;
  display: block;
  overflow: hidden;
  margin-bottom: 30px;
}

.team-two__single.mb40 {
  margin-top: 40px;
}

.team-two__single-img {
  position: relative;
  display: block;
}

.team-two__single-img .inner {
  position: relative;
  display: block;
  overflow: hidden;
}

.team-two__single-img .inner:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition-delay: .1s;
  transition-timing-function: ease-in-out;
  transition-duration: .7s;
  transition-property: all;
  background: rgba(0, 0, 0, .5);
  opacity: 0;
  z-index: 1;
  content: "";
}

.team-two__single:hover .team-two__single-img .inner::before {
  opacity: 1;
}

.team-two__single-img .inner .social-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  background: var(--thm-base);
  padding: 11px 10px 14px;
  border-radius: 30px;
  border-bottom-right-radius: 0px;
  z-index: 5;
  opacity: 0;
  -webkit-transform: translateX(200px);
  transform: translateX(200px);
  -webkit-transition: all 700ms ease;
  transition: all 700ms ease;
}

.team-two__single:hover .team-two__single-img .inner .social-icon {
  transform: scale(1.0);
  opacity: 1;
}

.team-two__single-img .inner .social-icon li {
  position: relative;
  display: block;
  margin-bottom: 5px;
}

.team-two__single-img .inner .social-icon li:last-child {
  margin-bottom: 0;
}

.team-two__single-img .inner .social-icon li a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: var(--thm-base);
  border-radius: 50%;
  z-index: 1;
}

.team-two__single-img .inner .social-icon li a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #ffffff;
  border-radius: 50%;
  border-bottom-right-radius: 0px;
  transform: scale(0.5);
  opacity: 0;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
  z-index: -1;
}

.team-two__single-img .inner .social-icon li a:hover::before {
  transform: scale(1.0);
  opacity: 1;
}

.team-two__single-img .inner .social-icon li a span::before {
  position: relative;
  display: inline-block;
  color: #ffffff;
  font-size: 15px;
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.team-two__single-img .inner .social-icon li a:hover span::before {
  color: var(--thm-base);
}

.team-two__single-img .inner img {
  width: 100%;
  transition: .5s ease;
  transform: scale(1.05);
}

.team-two__single:hover .team-two__single-img .inner img {
  transform: scale(1);
}

.team-two__single-content {
  position: relative;
  display: block;
  width: 285px;
  background: var(--thm-base);
  border-radius: 10px;
  padding: 19px 0px 17px;
  margin-top: -64px;
  z-index: 5;
  opacity: 0;
  -webkit-transform: translateX(-200px);
  transform: translateX(-200px);
  -webkit-transition: all 700ms ease;
  transition: all 700ms ease;
}

.team-two__single:hover .team-two__single-content {
  opacity: 1;
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
}

.team-two__single-content h2 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  text-transform: capitalize;
  color: #ffffff;
}

.team-two__single-content h2 a {
  color: #ffffff;
}

.team-two__single-content p {
  color: #ffffff;
  font-size: 17px;
  line-height: 28px;
  text-transform: capitalize;
  margin: 0;
}


/***
=============================================
Portfolio Two
=============================================
***/
.portfolio-two {
  position: relative;
  display: block;
  background: #eff0f0;
  padding: 70px 0px 120px;
  z-index: 1;
}

.portfolio-two__top {
  position: relative;
  display: block;
}

.portfolio-two .container-fluid {
  padding-right: var(--bs-gutter-x, .0rem);
  padding-left: var(--bs-gutter-x, .0rem);
}

.portfolio-two__single {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.owl-carousel .owl-item.active:nth-child(odd) .portfolio-two__single {
  margin-top: 0px;
}

.owl-carousel .owl-item.active:nth-child(even) .portfolio-two__single {
  margin-top: 30px;
}

.portfolio-two__single-img {
  position: relative;
  display: block;
  overflow: hidden;
}

.portfolio-two__single-img:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition-delay: .1s;
  transition-timing-function: ease-in-out;
  transition-duration: .7s;
  transition-property: all;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  z-index: 1;
  content: "";
}

.portfolio-two__single:hover .portfolio-two__single-img:before {
  opacity: 1;
}

.portfolio-two__single-img img {
  width: 100%;
  transition: .5s ease;
  transform: scale(1.05);
}

.portfolio-two__single:hover .portfolio-two__single-img img {
  transform: scale(1);
}

.portfolio-two__single-img .overlay-content {
  position: absolute;
  left: 40px;
  bottom: 40px;
  right: 40px;
  background: #ffffff;
  padding: 40px 20px 40px 80px;
  overflow: hidden;
  text-align: right;
  -webkit-transform: perspective(400px) rotateX(90deg) scaleY(0.5);
  -ms-transform: perspective(400px) rotateX(90deg) scaleY(0.5);
  transform: perspective(400px) rotateX(90deg) scaleY(0.5);
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  transition-delay: .1s;
  transition-timing-function: ease-in-out;
  transition-duration: .5s;
  transition-property: all;
  z-index: 5;
}

.portfolio-two__single:hover .portfolio-two__single-img .overlay-content {
  -webkit-transform: perspective(400px) rotateX(0deg) scaleY(1.0);
  -ms-transform: perspective(400px) rotateX(0deg) scaleY(1.0);
  transform: perspective(400px) rotateX(0deg) scaleY(1.0);
  transition-delay: .1s;
  transition-timing-function: ease-in-out;
  transition-duration: .7s;
  transition-property: all;
}

.portfolio-two__single-img .overlay-content::before {
  position: absolute;
  top: 0;
  left: 60px;
  bottom: 0;
  width: 2px;
  background: var(--thm-base);
  content: "";
  transition: -webkit-transform 2.0s ease;
  transition: transform 2.0s ease;
  transition: transform 2.0s ease, -webkit-transform 2.0s ease;
  transform-origin: bottom right;
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
}

.portfolio-two__single:hover .portfolio-two__single-img .overlay-content::before {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  transform-origin: top center;
}

.portfolio-two__single-img .overlay-content::after {
  position: absolute;
  left: 0px;
  bottom: 40px;
  right: 50%;
  height: 2px;
  background: var(--thm-base);
  content: "";
  transition: transform 2000ms ease;
  transform: scale(0, 1);
  transform-origin: left center;
}

.portfolio-two__single:hover .portfolio-two__single-img .overlay-content::after {
  transform: scale(1, 1);
  transform-origin: left center;
}

.portfolio-two__single-img .overlay-content h2 {
  font-size: 22px;
  line-height: 34px;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 12px;
  opacity: 0;
  transform: translateY(-50px);
  transition: all 700ms ease;
}

.portfolio-two__single:hover .portfolio-two__single-img .overlay-content h2 {
  opacity: 1;
  transform: translateY(0%);
  transition-delay: 700ms;
}

.portfolio-two__single-img .overlay-content h2 a {
  color: var(--thm-base2);
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.portfolio-two__single-img .overlay-content h2 a:hover {
  color: var(--thm-base);
}

.portfolio-two__single-img .overlay-content p {
  margin: 0;
  opacity: 0;
  transform: translateY(50px);
  transition: all 700ms ease;
}

.portfolio-two__single:hover .portfolio-two__single-img .overlay-content p {
  opacity: 1;
  transform: translateY(0%);
  transition-delay: 500ms;
}


.portfolio-two .owl-theme .owl-nav {
  position: absolute;
  margin-top: 0;
  top: -120px;
  right: 19%;
}

.portfolio-two .owl-carousel .owl-nav button.owl-prev,
.portfolio-two .owl-carousel .owl-nav button.owl-next {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 55px;
  border-radius: 0px;
  background-color: #ffffff;
  color: var(--thm-black);
  font-size: 20px;
  outline: none;
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.portfolio-two .owl-carousel .owl-nav button.owl-prev:hover,
.portfolio-two .owl-carousel .owl-nav button.owl-next:hover {
  background: var(--thm-base);
  color: #ffffff;
}



/***
=============================================
Feature Three
=============================================
***/
.feature-three .auto-container {
  max-width: 1650px;
  width: 100%;
}

.feature-three {
  position: relative;
  display: block;
  padding: 120px 0px 240px;
  overflow: hidden;
  z-index: 1;
}

.feature-three .shape1 {
  position: absolute;
  top: -390px;
  right: -130px;
  z-index: 2;
}

.feature-three .shape2 {
  position: absolute;
  top: -200px;
  right: -290px;
  mix-blend-mode: soft-light;
  z-index: 1;
}

.feature-three__bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-attachment: scroll;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  z-index: -1;
  background-image: url('../images/backgrounds/values-bg.webp');
}

.feature-three__bg::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #010007;
  opacity: 0.7;
  content: "";
  z-index: -2;
}

.feature-three__pattern {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-attachment: scroll;
  background-position: center center;
  background-repeat: repeat;
  z-index: -1;
  background-image: url('../images/pattern/thm-pattern.png');
}

.feature-three__inner {
  position: relative;
  display: block;
  background: #ffffff;
  box-shadow: 0px 10px 60px 0px rgb(0, 0, 0, 0.07);
  padding: 50px 15px 50px;
  overflow: hidden;
  border-radius: 50px;
  border-top-left-radius: 0px;
  z-index: 5;
}

.feature-three__box {
  position: relative;
  display: block;
}

.feature-three__single {
  position: relative;
  display: block;
  width: 14.2%;
  float: left;
}

.feature-three__single .icon-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: var(--thm-base);
  margin: 0 auto;
}

.feature-three__single .icon-box span::before {
  position: relative;
  display: inline-block;
  color: #ffffff;
  font-size: 50px;
  transition: all 500ms linear;
  transition-delay: 0.1s;
  transform: scale(1);
}

.feature-three__single:hover .icon-box span::before {
  transform: scale(0.9);
}

.feature-three__single .title-box {
  position: relative;
  display: block;
}

.feature-three__single .title-box h2 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  text-transform: capitalize;
  margin-top: 11px;
  color: var(--thm-black);
}

.feature-three__single .title-box h2 a {
  color: var(--thm-black);
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.feature-three__single .title-box h2 a:hover {
  color: var(--thm-base);
}


/***
=============================================
Industry Skill One
=============================================
***/
.industry-skill-one {
  position: relative;
  display: block;
  padding: 70px 0px 60px;
  margin-top: -120px;
  z-index: 3;
}

.industry-skill-one .shape1 {
  position: absolute;
  top: 140px;
  left: 115px;
  opacity: 0.03;
  z-index: -1;
}

.industry-skill-one::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 370px;
  background: #ffffff;
  content: "";
  z-index: -1;
}

.industry-skill-one__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 50px;
  background-attachment: scroll;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 745px;
  background-image: url('../images/about/goals-objectives.jpg');
}

.industry-skill-one__content {
  position: relative;
  display: block;
  max-width: 700px;
  width: 100%;
  margin-left: 50px;
}

.industry-skill-one__content .sec-title-two {
  padding-bottom: 22px;
}

.industry-skill-one__content .text-box {
  position: relative;
  display: block;
  margin-bottom: 23px;
}

.industry-skill-one__content .text-box p {
  margin: 0px;
  text-align: justify;
}


.industry-skill-one__progress {
  position: relative;
  display: block;
  width: 100%;
}

.industry-skill-one__progress-single {
  position: relative;
  display: block;
  margin-bottom: 15px;
}

.industry-skill-one__progress-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  text-transform: capitalize;
  margin-bottom: 15px;
}

.industry-skill-one__progress .bar {
  position: relative;
  width: 100%;
  height: 8px;
  background: #ffffff;
}

.industry-skill-one__progress .bar-inner {
  position: relative;
  display: block;
  width: 0px;
  height: 8px;
  background: var(--thm-base);
  border-bottom-right-radius: 0px;
  -webkit-transition: all 1500ms ease;
  -ms-transition: all 1500ms ease;
  -o-transition: all 1500ms ease;
  -moz-transition: all 1500ms ease;
  transition: all 1500ms ease;
}

.industry-skill-one__progress .bar::before {
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  border: 2px solid var(--thm-base);
  content: "";
}

.industry-skill-one__progress .count-text {
  position: absolute;
  top: -35px;
  right: 0px;
  color: var(--thm-black);
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  text-align: center;
  opacity: 0;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  transition: all 500ms ease;
  opacity: 1;
}


/***
=============================================
Services One Services
=============================================
***/
.services-one--services {
  position: relative;
  display: block;
  padding: 80px 0px 50px;
}


/***
=============================================
Services Details
=============================================
***/
.services-details {
  position: relative;
  display: block;
  background: #ffffff;
  padding: 120px 0px 160px;
  z-index: 1;
}

.services-details__content-text-box1-list {
  position: relative;
  display: block;
  margin-top: 15px;
  margin-bottom: 15px;
}

.services-details__content-text-box1-list li {
  position: relative;
  display: block;
  margin-bottom: 10px;
}

.services-details__content-text-box1-list li:last-child {
  margin-bottom: 0px;
}

.services-details__content-text-box1-list li p {
  position: relative;
  padding-left: 20px;
}

.services-details__content-text-box1-list li p::before {
  position: absolute;
  top: 10px;
  left: 0;
  width: 8px;
  height: 8px;
  background: var(--thm-black);
  border-radius: 50%;
  content: "";
}

/***
=============================================
Portfolio Page
=============================================
***/
.portfolio-page {
  position: relative;
  display: block;
  background: #ffffff;
  padding: 70px 0px 50px;
  z-index: 1;
}

.complete-projects .container {
  max-width: 1470px;
}

.complete-projects .container .row>* {
  padding-right: calc(25px/ 2);
  padding-left: calc(25px/ 2);
}

.portfolio-page__top {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 60px;
}

.portfolio-page__top .sec-title {
  padding-bottom: 0px;
}

.portfolio-page__top .btn-box {
  position: relative;
  display: block;
  line-height: 0;
}

.portfolio-page__single {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.portfolio-page__single-img {
  position: relative;
  display: block;
  overflow: hidden;
  z-index: 1;
}

.portfolio-page__single-img:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background: rgba(var(--thm-black-rgb), 0.5);
  transform: scaleX(0.0);
  transform-origin: center;
  transform-style: preserve-3d;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
  z-index: 1;
}

.portfolio-page__single:hover .portfolio-page__single-img:before {
  transform: scaleX(1.0);
}

.portfolio-page__single-img img {
  width: 100%;
  transition: all 500ms ease;
  transform: scale(1);
}

.portfolio-page__single:hover .portfolio-page__single-img img {
  transform: scale(1.05);
}

.portfolio-page__single-img .overlay-content {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  left: 0;
  bottom: 0;
  right: 0px;
  background: #ffffff;
  padding: 21px 30px 25px;
  transform: perspective(1200px) rotateX(0deg);
  transition: all .7s ease .2s;
  transform-origin: bottom;
  /* transition: all 700ms ease 100ms; */
  z-index: 5;
}

.portfolio-page__single:hover .portfolio-page__single-img .overlay-content {
  transform: perspective(1200px) rotateX(0deg);
  transition: all 700ms ease 200ms;
}

.portfolio-page__single-img .overlay-content .text-box {
  position: relative;
  display: block;
}

.portfolio-page__single-img .overlay-content .text-box h3 {
  font-size: 22px;
  line-height: 30px;
  font-weight: 600;
  text-transform: capitalize;
}

.portfolio-page__single-img .overlay-content .text-box h3 a {
  color: var(--thm-base2);
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.portfolio-page__single-img .overlay-content .text-box h3 a:hover {
  color: var(--thm-base);
}

.portfolio-page__single-img .overlay-content .text-box p {
  font-size: 16px;
  line-height: 26px;
  margin: 0;
}

.portfolio-page__single-img .overlay-content .btn-box {
  position: relative;
  display: block;
}

.portfolio-page__single-img .overlay-content .btn-box a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: var(--thm-base);
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.portfolio-page__single-img .overlay-content .btn-box a:hover {
  background: var(--thm-black);
}

.portfolio-page__single-img .overlay-content .btn-box a span::before {
  position: relative;
  display: inline-block;
  color: #ffffff;
  font-size: 17px;
}

.portfolio-page__link {
  position: absolute;
  top: 30px;
  right: 30px;
  opacity: 0;
  transform: scale(0);
  transition: all 700ms ease;
  z-index: 2;
}

.portfolio-page__single:hover .portfolio-page__link {
  transform: scale(1);
  opacity: 1;
  transition-delay: 500ms;
}

.portfolio-page__link a {
  position: relative;
  display: block;
  height: 50px;
  width: 50px;
  background-color: var(--thm-base);
  border-radius: 50%;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  transition: .4s;
  -webkit-transition: all .4s ease-in-out;
}

.portfolio-page__link a:hover {
  color: var(--thm-base);
  background: #ffffff;
}

.portfolio-page__link a span::before {
  position: relative;
  display: inline-block;
  line-height: 50px;
}

.portfolio-page__pagination {
  position: relative;
  display: block;
  margin-top: 20px;
}



/***
=============================================
Portfolio Details
=============================================
***/
.portfolio-details {
  position: relative;
  display: block;
  background: #ffffff;
  padding: 70px 0px 70px;
}

.portfolio-details__sidebar {
  position: relative;
  display: block;
}

.portfolio-details__single {
  position: relative;
  display: block;
  margin-bottom: 30px;
}


.portfolio-details__single.project-details {
  position: relative;
  display: block;
  background: #f1f3f8;
  padding: 40px 30px 40px;
}

.sidebar__title {
  position: relative;
  margin: 0;
  font-size: 27px;
  line-height: 37px;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 25px;
  padding-left: 40px;
}

.sidebar__title::before {
  position: absolute;
  left: 0;
  bottom: 9px;
  height: 2px;
  width: 30px;
  background: var(--thm-base);
  content: "";
}

.portfolio-details__sidebar-list {
  position: relative;
  display: block;
}

.portfolio-details__sidebar-list li {
  position: relative;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  background: #ffffff;
  padding: 12px 20px 12px;
  margin-bottom: 15px;
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.portfolio-details__sidebar-list li:hover {
  background: var(--thm-base);
}

.portfolio-details__sidebar-list li:last-child {
  margin-bottom: 0;
}

.portfolio-details__sidebar-list li .left-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 105px;
}

.portfolio-details__sidebar-list li .left-box p {
  color: var(--thm-black);
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  text-transform: capitalize;
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.portfolio-details__sidebar-list li:hover .left-box p {
  color: #ffffff;
}

.portfolio-details__sidebar-list li .left-box .dots {
  position: relative;
  display: inline-block;
  color: var(--thm-black);
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.portfolio-details__sidebar-list li:hover .left-box .dots {
  color: #ffffff;
}

.portfolio-details__sidebar-list li .right-box {
  position: relative;
  display: block;
  margin-left: 12px;
}

.portfolio-details__sidebar-list li .right-box p {
  color: var(--thm-black);
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  text-transform: capitalize;
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.portfolio-details__sidebar-list li:hover .right-box p {
  color: #ffffff;
}

.portfolio-details__sidebar-list li .right-box ul {
  position: relative;
  display: block;
}

.portfolio-details__sidebar-list li .right-box ul li {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0;
  background: transparent;
  margin-right: 5px;
}

.portfolio-details__sidebar-list li .right-box ul li a {
  position: relative;
  display: block;
  color: var(--thm-black);
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  text-transform: capitalize;
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.portfolio-details__sidebar-list li:hover .right-box ul li a {
  color: #ffffff;
}


.portfolio-details__content {
  position: relative;
  display: block;
}

.portfolio-details__content-img1 {
  position: relative;
  display: block;
  margin-bottom: 41px;
}

/**/
.portfolio-one__carousel.owl-carousel .owl-item img {
  width: 100%;
}

.portfolio-details .owl-carousel .owl-nav button.owl-prev {
  position: absolute;
  top: 50%;
  left: 15px;
  width: 55px;
  height: 55px;
  border-radius: 0px;
  background-color: rgba(255, 255, 255, .4);
  color: rgba(255, 255, 255, .7);
  font-size: 20px;
  outline: none;
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.portfolio-details .owl-carousel .owl-nav button.owl-next {
  right: 15px;
  position: absolute;
  top: 50%;
  width: 55px;
  height: 55px;
  border-radius: 0px;
  background-color: rgba(255, 255, 255, .4);
  color: rgba(255, 255, 255, .7);
  font-size: 20px;
  outline: none;
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.portfolio-details .owl-carousel .owl-nav button.owl-prev:hover,
.portfolio-details .owl-carousel .owl-nav button.owl-next:hover {
  background: var(--thm-base);
  color: #ffffff;
}

/**/
.portfolio-details__content-img1 img {
  width: 100%;
}

.portfolio-details__content-text-box1 {
  position: relative;
  display: block;
}

.portfolio-details__content-text-box1 h2 {
  font-size: 32px;
  line-height: 42px;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 21px;
}

.portfolio-details__content-text-box1 p {
  margin: 0px;
  margin-bottom: 17px;
}

.portfolio-details__content-text-box1 .text2 {
  margin: 0;
}


.portfolio-details__content-text-box2 {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 26px;
}

.portfolio-details__content-text-box2 .img-box {
  position: relative;
  display: block;
  border-radius: 10px;
  overflow: hidden;
}

.portfolio-details__content-text-box2 .img-box img {
  width: 100%;
}


.portfolio-details__content-text-box2 .content-box {
  position: relative;
  display: block;
  margin-left: 30px;
}

.portfolio-details__content-text-box2 .content-box ul {
  position: relative;
  display: block;
}

.portfolio-details__content-text-box2 .content-box ul li {
  position: relative;
  display: block;
  padding-left: 35px;
  margin-bottom: 17px;
}

.portfolio-details__content-text-box2 .content-box ul li:last-child {
  margin-bottom: 0;
}

.portfolio-details__content-text-box2 .content-box ul li .icon-box {
  position: absolute;
  top: 2px;
  left: 0;
}

.portfolio-details__content-text-box2 .content-box ul li .icon-box span:before {
  position: relative;
  display: inline-block;
  color: var(--thm-base);
  font-size: 25px;
}

.portfolio-details__content-text-box2 .content-box ul li .text-box {
  position: relative;
  display: block;
}

.portfolio-details__content-text-box2 .content-box ul li .text-box p {
  margin: 0;
}

.portfolio-details__content-text-box3 {
  position: relative;
  display: block;
  margin-top: 27px;
}

.portfolio-details__content-text-box3-list {
  position: relative;
  display: block;
}

.portfolio-details__content-text-box3-list li {
  position: relative;
  display: block;
  margin-bottom: 10px;
}

.portfolio-details__content-text-box3-list li:last-child {
  margin-bottom: 0px;
}

.portfolio-details__content-text-box3-list li p {
  position: relative;
  padding-left: 25px;
}

.portfolio-details__content-text-box3-list li p::before {
  position: absolute;
  top: 10px;
  left: 0;
  width: 10px;
  height: 10px;
  background: #bfc2c3;
  border-radius: 50%;
  content: "";
}

.portfolio-details__content-img2 {
  position: relative;
  display: block;
  overflow: hidden;
  margin-top: 46px;
  z-index: 1;
}

.portfolio-details__content-img2::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(var(--thm-black-rgb), 0.4);
  content: "";
  z-index: 1;
}

.portfolio-details__content-img2 img {
  width: 100%;
}

.portfolio-details__content-img2 .icon {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.portfolio-details__content-img2 .icon a {
  position: relative;
  display: inline-block;
  width: 90px;
  height: 90px;
  line-height: 90px;
  color: #ffffff;
  font-size: 30px;
  text-align: center;
  border-radius: 50%;
  background: var(--thm-base);
  z-index: 1;
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.portfolio-details__content-img2 .icon a:hover {
  background: var(--thm-black);
}

.portfolio-details__content-img2 .icon a::before {
  position: absolute;
  top: -15px;
  left: -15px;
  bottom: -15px;
  right: -15px;
  border: 1px solid var(--thm-base);
  border-radius: 50%;
  content: "";
}

.portfolio-details__content-text-box4 {
  position: relative;
  display: block;
  margin-top: 46px;
}

.portfolio-details__content-text-box4 p {
  margin: 0;
}

.portfolio-details__content-img3 {
  position: relative;
  display: block;
  margin-top: 46px;
}

.portfolio-details__content-img3 .single-box {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.portfolio-details__content-img3 .single-box .img-box {
  position: relative;
  display: block;
  overflow: hidden;
}

.portfolio-details__content-img3 .single-box .img-box img {
  width: 100%;
}


.portfolio-details__content-text-box5 {
  position: relative;
  display: block;
  margin-top: 11px;
}

.portfolio-details__content-text-box5 p {
  margin: 0;
}

.portfolio-details__content-text-box5-list {
  position: relative;
  display: block;
  margin-top: 17px;
}

.portfolio-details__content-text-box5-list li {
  position: relative;
  display: block;
  margin-bottom: 10px;
}

.portfolio-details__content-text-box5-list li:last-child {
  margin-bottom: 0px;
}

.portfolio-details__content-text-box5-list li p {
  position: relative;
  padding-left: 25px;
}

.portfolio-details__content-text-box5-list li p::before {
  position: absolute;
  top: 10px;
  left: 0;
  width: 10px;
  height: 10px;
  background: #bfc2c3;
  border-radius: 50%;
  content: "";
}


/***
=====================================================
  Comments
=====================================================
***/
.comment-one {
  position: relative;
  display: block;
  margin-top: 42px;
}

.comment-one__title {
  color: var(--thm-black);
  font-size: 30px;
  line-height: 40px;
  font-weight: 500;
  text-transform: capitalize;
  margin: 0;
  margin-bottom: 23px;
}

.comment-one__single {
  position: relative;
  display: block;
  padding-left: 100px;
  margin-bottom: 53px;
}

.comment-one__single.style2 {
  position: relative;
  display: block;
  margin-left: 80px;
}

.comment-one__image {
  position: absolute;
  top: 12px;
  left: 0;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
}

.comment-one__image img {
  width: 100%;
}

.comment-one__content {
  position: relative;
  display: block;
  padding-left: 20px;
}

.comment-one__content::before {
  position: absolute;
  top: 12px;
  left: 0;
  bottom: 0;
  width: 1px;
  background: #e9e9e9;
  content: "";
}

.comment-one__content span {
  color: var(--thm-base);
  font-size: 13px;
  line-height: 20px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

.comment-one__content h3 {
  margin: 0;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 4px;
  font-weight: 500;
}

.comment-one__content p {
  margin: 0;
}

.comment-one__content .btn-box {
  position: relative;
  display: block;
  margin-top: 16px;
}

.comment-one__content .btn-box a {
  position: relative;
  display: inline-block;
  border: 1px solid #d2d2d4;
  color: var(--thm-black);
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 4px 25px 3px;
  border-radius: 16px;
}

.comment-one__content .btn-box a+a {
  margin-left: 6px;
}

.comment-one__content .btn-box a:hover {
  color: #ffffff;
  background: var(--thm-base);
  border-color: var(--thm-base);
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.comment-form {
  position: relative;
  display: block;
}

.comment-form__title {
  color: var(--thm-black);
  font-size: 30px;
  line-height: 40px;
  font-weight: 600;
  text-transform: capitalize;
  margin: 0;
  margin-bottom: 40px;
}

.comment-one__form {
  position: relative;
  display: block;
}

.comment-form__input-box {
  position: relative;
  display: block;
  margin-bottom: 20px;
}

.comment-form__input-box input[type="text"],
.comment-form__input-box input[type="email"] {
  position: relative;
  display: block;
  background: #f1f3f8;
  width: 100%;
  height: 50px;
  border: 0px solid #dcdfe2;
  color: #8b9094;
  font-size: 16px;
  font-weight: 400;
  text-transform: none;
  font-style: normal;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 0px;
  transition: all 500ms ease;
  font-family: var(--thm-font);
  outline: none;
}

.comment-form__input-box textarea {
  width: 100%;
  height: 150px;
  background-color: #f1f3f8;
  border: 0px solid #dcdfe2;
  outline: none;
  color: #8b9094;
  font-size: 16px;
  font-weight: 400;
  text-transform: none;
  border-radius: 0px;
  padding: 12px 20px 20px;
  font-family: var(--thm-font);
  resize: none;
}

.comment-form__btn-box {
  position: relative;
  display: block;
  padding-top: 2px;
}

.comment-form__btn {
  position: relative;
  outline: none;
}


/***
=============================================
Contact Page
=============================================
***/
.contact-page {
  position: relative;
  display: block;
  background: #ffffff;
  padding: 70px 0px 70px;
}

.contact-page .auto-container {
  max-width: 1650px;
  width: 100%;
}

.contact-page__top {
  position: relative;
  display: block;
  padding-bottom: 70px;
}

.contact-page__top-single {
  position: relative;
  display: block;
  background: #ffffff;
  box-shadow: 0px 0px 35px 0px rgb(0, 0, 0, 0.05);
  border: 1px solid #f2f4fb;
  border-radius: 5px;
  padding: 40px 5px 31px;
  margin-bottom: 30px;
}

.contact-page__top-single .icon-box {
  position: relative;
  display: block;
  margin-bottom: 20px;
}

.contact-page__top-single .icon-box .fa-phone {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.contact-page__top-single .icon-box span:before {
  position: relative;
  display: inline-block;
  color: var(--thm-base);
  font-size: 60px;
}

.contact-page__top-single .content-box {
  position: relative;
  display: block;
}

.contact-page__top-single .content-box h2 {
  font-size: 28px;
  line-height: 38px;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 9px;
}

.contact-page__top-single .content-box p {
  font-size: 22px;
  line-height: 32px;
  font-weight: 400;
  color: var(--thm-black);
}

.contact-page__top-single .content-box p a {
  display: block;
  color: var(--thm-black);
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.contact-height{
  height: 295px;
}

.contact-page__top-single .content-box p a:hover {
  color: var(--thm-base2);
}

.contact-page__bottom {
  position: relative;
  display: block;
}

.contact-page__form {
  position: relative;
  display: block;
}

.contact-page__form .title-box {
  position: relative;
  display: block;
  margin-bottom: 48px;
}

.contact-page__form .title-box h2 {
  font-size: 45px;
  line-height: 55px;
  font-weight: 600;
}


.contact-page__form .comment-form__input-box {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.contact-page__form .comment-form__input-box input[type="text"],
.contact-page__form .comment-form__input-box input[type="email"],
.contact-page__form .comment-form__input-box input[type="number"] {
  position: relative;
  display: block;
  background: #ffffff;
  width: 100%;
  height: 60px;
  border: 1px solid #e0e1e1;
  color: #606d78;
  font-size: 15px;
  font-weight: 400;
  text-transform: none;
  font-style: normal;
  padding-left: 30px;
  padding-right: 20px;
  border-radius: 0px;
  letter-spacing: 0.02em;
  transition: all 500ms ease;
  font-family: var(--thm-font);
  outline: none;
}

.contact-page__form .comment-form__input-box textarea {
  width: 100%;
  height: 150px;
  background-color: #ffffff;
  border: 1px solid #e0e1e1;
  outline: none;
  color: #606d78;
  font-size: 15px;
  font-weight: 400;
  text-transform: none;
  border-radius: 0px;
  padding: 15px 30px 20px;
  font-family: var(--thm-font);
  letter-spacing: 0.02em;
  resize: none;
}

.contact-page__map {
  position: relative;
  display: block;
  margin-top: 12px;
}

.contact-page__google-map {
  position: relative;
  display: block;
  border: none;
  height: 580px;
  width: 100%;
}


/***
=============================================
Services Three
=============================================
***/
.services-three {
  position: relative;
  display: block;
  background: #f9f9f9;
  padding: 70px 0px 60px;
  z-index: 1;
}

.services-three::before {
  position: absolute;
  left: 580px;
  right: 0;
  height: 450px;
  background: #ffffff;
  bottom: 0;
  content: "";
  z-index: 0;
}

.services-three__bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-attachment: scroll;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 0.03;
  z-index: -1;
  background-image: url('../images/backgrounds/services-v3-bg.webp');
}

.services-three .big-title {
  position: absolute;
  right: 200px;
  bottom: 75px;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg) translateX(0%);
  width: max-content;
  z-index: 2;
}

.services-three .big-title h2 {
  color: rgba(var(--thm-base-rgb), 0.07);
  font-size: 100px;
  font-weight: 400;
  letter-spacing: 0.18em;
  text-transform: uppercase;
  font-family: var(--thm-font);

}

.services-three__top {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;
}

.services-three__top .sec-title-three {
  padding-bottom: 0px;
}

.services-three__top .counter-box {
  position: relative;
  display: block;
}

.services-three__top .counter-box h2 {
  position: relative;
  color: var(--thm-base);
  font-size: 60px;
  line-height: 0.8em;
  font-weight: 600;
}

.services-three__top .counter-box h2 .plus {
  position: absolute;
  top: -6px;
  font-size: 40px;
}

.services-three__top .counter-box p {
  color: var(--thm-black);
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  text-transform: capitalize;
}

.services-three__top .odometer.odometer-auto-theme,
.services-three__top .odometer.odometer-theme-default {
  font-family: var(--thm-font);
  line-height: 0.8em;
}

.services-three__left {
  position: relative;
  display: block;
  margin-bottom: 30px;
  z-index: 3;
}

.services-three__left-img {
  position: relative;
  display: block;
  overflow: hidden;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.services-three__left-img img {
  width: 100%;
}

.services-three__left-img .content-box {
  position: absolute;
  right: 10px;
  bottom: 0;
  background: #ffffff;
  box-shadow: 0px 10px 30px 0px rgb(0, 0, 0, 0.07);
  padding: 20px 30px 26px;
  border-top-left-radius: 10px;
}

.services-three__left-img .content-box span {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
}

.services-three__left-img .content-box h3 {
  font-size: 22px;
  line-height: 32px;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 13px;
}

.services-three__left-img .content-box p {
  margin: 0;
}

.services-three__left-img .content-box .btn-box {
  position: relative;
  display: block;
  margin-top: 11px;
}

.services-three__left-img .content-box .btn-box a {
  color: var(--thm-black);
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  text-transform: capitalize;
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.services-three__left-img .content-box .btn-box a:hover {
  color: var(--thm-base);
}

.services-three__left-img .content-box .btn-box a i:before {
  position: relative;
  display: inline-block;
  color: var(--thm-black);
  font-size: 15px;
  font-weight: 700;
  top: 1px;
  margin-right: 5px;
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.services-three__left-img .content-box .btn-box a:hover i:before {
  color: var(--thm-base);
}

.services-three__right {
  position: relative;
  display: block;
}

.services-three__right-single {
  position: relative;
  display: block;
  background: #ffffff;
  box-shadow: 0px 10px 60px 0px rgb(0, 0, 0, 0.07);
  padding: 43px 45px 40px;
  margin-bottom: 30px;
  z-index: 3;
}

.services-three__right-single .inner {
  position: relative;
  display: block;
  padding-left: 80px;
}

.services-three__right-single .inner .icon-box {
  position: absolute;
  top: 7px;
  left: 0;
  z-index: 1;
}

.services-three__right-single .inner .icon-box .bg-box {
  position: absolute;
  top: 0;
  left: -15px;
  width: 40px;
  height: 40px;
  background: #edf3f6;
  content: "";
  z-index: -1;
}

.services-three__right-single .inner .icon-box span::before {
  position: relative;
  display: inline-block;
  color: var(--thm-base);
  font-size: 60px;
  transition: all 500ms linear;
  transition-delay: 0.1s;
  transform: scale(1);
}

.services-three__right-single:hover .inner .icon-box span::before {
  transform: scale(0.9);
}

.services-three__right-single .inner .content-box {
  position: relative;
  display: block;
}

.services-three__right-single .inner .content-box h2 {
  font-size: 22px;
  line-height: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.services-three__right-single .inner .content-box h2 a {
  color: var(--thm-black);
  transition: all 200ms linear;
  transition-delay: 0.1s;

}

.services-three__right-single .inner .content-box h2 a:hover {
  color: var(--thm-base);
}

.services-three__right-single .inner .content-box p {
  margin: 0;
  text-align: justify;
}


/***
=============================================
Feature Six
=============================================
***/
.feature-six {
  position: relative;
  display: block;
  background: #ffffff;
  padding: 70px 0px 70px;
  overflow: hidden;
}

.feature-six__bg {
  position: absolute;
  top: 15%;
  left: 0;
  bottom: 120px;
  right: 0;
  z-index: 1;
  width: 48.3%;
  /* background-position: bottom right;
  background-repeat: no-repeat;
  background-size: cover;  
  background-image: url('../images/backgrounds/feature-v6-bg.png'); */
}

.feature-six__inner {
  position: relative;
  display: block;
}

.feature-six__content {
  position: relative;
  display: block;
  max-width: 675px;
  width: 100%;
  float: right;
  margin-right: -130px;
}

.feature-six__content .sec-title-three {
  padding-bottom: 25px;
}

.feature-six__content .text-box1 {
  position: relative;
  display: block;
}

.feature-six__content .text-box1 p {
  margin: 0;
}

.feature-six__content-text2 {
  position: relative;
  display: block;
  margin-top: 51px;
}

.feature-six__content-text2-single {
  position: relative;
  display: block;
}

.feature-six__content-text2-single ul {
  position: relative;
  display: block;
}

.feature-six__content-text2-single ul li {
  position: relative;
  display: block;
  margin-bottom: 15px;
}

.feature-six__content-text2-single ul li:last-child {
  margin-bottom: 0;
}

.feature-six__content-text2-single ul li a {
  position: relative;
  display: block;
  color: var(--thm-black);
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  text-transform: capitalize;
  background: #ffffff;
  box-shadow: 0px 2px 30px 0px rgb(9, 24, 84, 0.05);
  padding: 20px 30px 18px;
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.feature-six__content-text2-single ul li a span::before {
  position: relative;
  display: inline-block;
  color: var(--thm-base);
  font-size: 16px;
  margin-right: 10px;
  top: 1px;
}

.feature-six__content-text2-single ul li a:hover {
  color: var(--thm-base);
}

.feature-six__content-text2-single .text-box {
  position: relative;
  display: block;
}

.feature-six__content-text2-single .text-box h2 {
  font-size: 28px;
  line-height: 38px;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 17px;
}

.feature-six__content-text2-single .text-box p {
  margin: 0;
}


.feature-six__progress {
  position: relative;
  display: block;
  width: 100%;
  margin-top: 32px;
}

.feature-six__progress-single {
  position: relative;
  display: block;
}

.feature-six__progress-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  text-transform: capitalize;
  margin-bottom: 17px;
}

.feature-six__progress .bar {
  position: relative;
  width: 100%;
  height: 12px;
  background: #faf7f7;
  border-radius: 6px;
}

.feature-six__progress .bar-inner {
  position: relative;
  display: block;
  width: 0px;
  height: 12px;
  background: var(--thm-base);
  border-radius: 6px;
  -webkit-transition: all 1500ms ease;
  -ms-transition: all 1500ms ease;
  -o-transition: all 1500ms ease;
  -moz-transition: all 1500ms ease;
  transition: all 1500ms ease;
}


.feature-six__progress .count-text {
  position: absolute;
  top: -35px;
  right: 0px;
  color: #7a838b;
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  text-align: center;
  opacity: 0;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  transition: all 500ms ease;
  opacity: 1;
}
/**/


/* our service */
.vision-wrapper {
  overflow: hidden;
  height: 650px;
  position: relative;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  z-index: 99
}

[data-overlay-dark]:before,
[data-overlay-light]:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1
}

[data-overlay-dark="5"]:before,
[data-overlay-light="5"]:before {
  opacity: 0.5;
}

.secondary-overlay[data-overlay-dark]:before {
  background: #001736;
}

.vision-changebg[data-overlay-dark]:before,
.vision-changebg[data-overlay-light]:before {
  z-index: -1
}

.vision-content {
  overflow: hidden;
  position: absolute;
  left: 0;
  bottom: -50px;
  width: 100%;
  padding: 25px;
  transition: all 0.3s;
  z-index: 9
}

.vision-wrapper:hover .vision-content {
  bottom: 10px
}

.vision-content p {
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease 0.1s
}

.vision-content .btn-box {
  position: relative;
  display: block;
  margin-top: 11px;
}

.vision-content .btn-box a {
  color: #ffffff;
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  text-transform: capitalize;
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.vision-content .btn-box a:hover {
  color: var(--thm-base2);
}

.vision-content .btn-box a i {
  margin-right: 10px;
}

.vision-wrapper:hover .vision-content p,
.vision-wrapper:hover .vision-content a {
  visibility: visible;
  opacity: 1
}

.vision-changebg.owl-theme .owl-nav {
  margin-top: 0
}

.vision-changebg.owl-theme .owl-dots .owl-dot span {
  background: rgba(255, 255, 255, 0.85)
}

.vision-changebg .owl-nav .owl-next {
  position: absolute;
  right: 15px;
  top: 35%;
  height: 55px;
  width: 55px;
  background: #fff !important;
  opacity: 1;
  border-radius: 0.25rem;
  line-height: 3rem !important;
  font-size: 18px !important;
  transition: all .3s ease
}

.vision-changebg .owl-nav .owl-next:hover {
  background: #001736 !important
}

.vision-changebg .owl-nav .owl-next:hover span {
  color: #fff
}

.vision-changebg .owl-nav .owl-next span {
  color: #001736;
  font-size: 24px;
  line-height: 55px;
  transition: all .3s ease
}

.vision-changebg .owl-nav .owl-prev {
  position: absolute;
  left: 15px;
  top: 35%;
  height: 55px;
  width: 55px;
  background: #fff !important;
  opacity: 1;
  border-radius: 0.25rem;
  line-height: 3rem !important;
  font-size: 18px !important;
  transition: all .3s ease
}

.vision-changebg .owl-nav .owl-prev:hover {
  background: #001736 !important
}

.vision-changebg .owl-nav .owl-prev span {
  color: #001736;
  font-size: 24px;
  line-height: 55px;
  transition: all .3s ease
}

.vision-changebg .owl-nav .owl-prev:hover span {
  color: #fff
}

.vision-changebg.owl-carousel.owl-theme .owl-dots {
  position: absolute;
  bottom: 80px;
  width: 100%;
  margin: 0;
  left: 0
}

@media screen and (min-width: 992px) {
  .vision-wrapper {
    background-image: none !important
  }

  .vision-content p {
    height: 70px
  }

  .vision-wrapper:hover .vision-content p {
    height: auto
  }
}

@media screen and (max-width: 1399px) {
  .vision-wrapper {
    height: 500px
  }

  .vision-content {
    bottom: -70px
  }
}

@media screen and (max-width: 1199px) {
  .vision-wrapper {
    height: 450px
  }

  .vision-content {
    bottom: -70px;
    padding: 15px
  }
}

@media screen and (max-width: 991px) {
  .vision-wrapper {
    border-bottom: none
  }

  .vision-content {
    bottom: -50px
  }

  .vision-wrapper .vision-content {
    bottom: 10px
  }

  .vision-content p {
    opacity: 1;
    visibility: visible
  }
}

@media screen and (max-width: 575px) {
  .vision-wrapper {
    height: 400px
  }
}

/* end our service */

/* about us home */
.about-section-home {
  padding: 90px 0px 70px;
  overflow: hidden;
  background: #fff;
  position: relative;
}

.align-items-center {
  align-items: center !important;
}

.overflow-visible {
  overflow: visible !important;
}

.mb-lg-0 {
  margin-bottom: 0 !important;
}

.me-xxl-2-9,
.mx-xxl-2-9 {
  margin-right: 2.9rem;
}

.pb-sm-12,
.py-sm-12 {
  padding-bottom: 6.5rem;
}

.text-sm-start {
  text-align: left !important;
}

.position-relative {
  position: relative !important;
}

.right {
  right: 0;
}

.d-sm-block {
  display: block !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.position-absolute {
  position: absolute !important;
}

.border-radius-10 {
  border-radius: 10px;
}

.border-solid {
  border: 7px solid #ffffff;
}

.about-style-02 .line-box1 {
  width: 180px;
  height: 200px;
  position: absolute;
  top: -20px;
  right: 100px;
  z-index: -1;
  border: 3px solid var(--thm-base2);
}

.about-style-02 .line-box2 {
  width: 10px;
  height: 70px;
  position: absolute;
  bottom: 7px;
  background-color: var(--thm-base2);
  left: 25%;
}

@media screen and (max-width: 575px) {
  .d-sm-block {
    display: block !important;
  }

  .d-none {
    display: none !important;
  }

  .pb-sm-12 {
    padding-top: 4.5rem;
    padding-bottom: 0rem;
  }

  .me-xxl-2-9 {
    margin-right: 0rem;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-1 {
    order: 1;
  }

  .about-style-02 .line-box1 {
    top: 20px;
  }
}

/**/
/***

====================================================================
	Service Tabs Style
====================================================================

***/
.service-detail {
  position: relative;
}

.service-detail .service-tabs {
  position: relative;
  margin-top: 10px;
}

.service-detail .service-tabs .tab-btns {
  position: relative;
  text-align: center;
  margin-bottom: 20px;
  border-bottom: 2px solid var(--thm-base2);
  ;
}

.service-detail .service-tabs .tab-btns .tab-btn {
  position: relative;
  display: block;
  width: 32.9%;
  margin-right: 0px;
  font-size: 20px;
  background: none;
  color: #222222;
  font-weight: 700;
  line-height: 24px;
  cursor: pointer;
  margin-bottom: 0px;
  display: inline-block;
  padding: 14px 40px 14px;
  transition: all 500ms ease;
  text-transform: capitalize;
}

.service-detail .service-tabs .tab-btns .tab-btn:hover,
.service-detail .service-tabs .tab-btns .tab-btn.active-btn {
  background-color: var(--thm-base2);
}

.service-detail .service-tabs .tabs-content {
  position: relative;
}

.service-detail .service-tabs .tabs-content .tab {
  position: relative;
  display: none;
}

.service-detail .service-tabs .tabs-content .tab.active-tab {
  display: block;
}

.service-detail .service-tabs .tabs-content .tab p {
  font-size: 18px;
  line-height: 1.8em;
  color: var(--thm-black);
  margin-bottom: 8px;
}

.service-detail .service-tabs .tabs-content .tab p:last-child {
  margin-bottom: 0px;
}

/***

====================================================================
	Gallery Section Two
====================================================================

***/

.certificates-section {
  position: relative;
  padding: 70px 0px 50px;
}

.certificates-section .certificates-block {
  margin-bottom: 50px;
}

.certificates-section .lower-text {
  position: relative;
  text-align: center;
  margin-top: 30px;
}

.certificates-section .lower-text .text {
  position: relative;
  color: #505050;
  font-size: 16px;
  font-weight: 600;
  padding: 18px 40px;
  border-radius: 50px;
  display: inline-block;
  border: 1px solid #acacac;
}

.certificates-section .lower-text .text a {
  position: relative;
  color: #fe982f;
  font-weight: 900;
}

.certificates-block {
  position: relative;
  margin-bottom: 30px;
}

.certificates-block .inner-box {
  position: relative;
  padding-right: 20px;
  padding-bottom: 20px;
}

.certificates-block .inner-box:before {
  position: absolute;
  content: '';
  right: 0px;
  top: 20px;
  left: 20px;
  bottom: 0px;
  background-color: #eeeeee;
}

.certificates-block .inner-box .image {
  position: relative;
}

.certificates-block .inner-box .image img {
  position: relative;
  width: 100%;
  display: block;
}

.certificates-block .inner-box .search-icon {
  position: absolute;
  right: 70px;
  bottom: -12px;
  width: 60px;
  height: 60px;
  color: var(--thm-base2);
  z-index: 2;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  line-height: 60px;
  border-radius: 50px;
  display: inline-block;
  background-color: #ffffff;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.10);
}

.certificates-block .inner-box .search-icon:before {
  position: absolute;
  content: '';
  right: -10px;
  top: -10px;
  bottom: -10px;
  left: -10px;
  z-index: -1;
  border-radius: 50%;
  border: 2px dashed var(--thm-base);
}

.certificates-block .inner-box .search-icon:before {
  animation-name: rotateme;
  animation-duration: 15s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-name: rotateme;
  -webkit-animation-duration: 15s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
}

@keyframes rotateme {

  0% {
    transform: rotate(0deg);
    opacity: 1;
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
    opacity: 1;
  }
}

.certificates-block .inner-box:hover .search-icon {
  background-color: var(--thm-base2);
  color: #ffffff;
}

/* plant-machinery */
.plant-machinery-section {
  position: relative;
  display: block;
  background: #ffffff;
  padding: 77px 0px 50px;
}

.plant-machinery-section h3 {
  color: var(--thm-base2);
  font-size: 30px;
  font-weight: 600;
}

table {
  margin: 0 0 1.5em;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0
}

th {
  font-weight: 500
}

td,
th {
  border-top: 1px solid var(--border-color);
  padding: 9px
}

.cart_table {
  border: 1px solid #eaf0f2;
  margin-bottom: 20px;
}

.mt-55 {
  margin-top: 55px;
}

.cart_table thead {
  background-color: #ecf0f1
}

.cart_table td:before,
.cart_table th {
  color: var(--thm-base);
  font-weight: 700;
  text-transform: uppercase;
  border: none;
  padding: 15px 15px
}

.cart_table td:before {
  content: attr(data-title);
  position: absolute;
  left: 15px;
  top: 50%;
  vertical-align: top;
  padding: 0;
  transform: translateY(-50%);
  display: none
}

.cart_table td {
  border: none;
  border-bottom: 1px solid #f3f3f3;
  color: #8b8b8b;
  padding: 15px 15px;
  position: relative;
  vertical-align: middle;
  font-weight: 500;
}

.cart_table .product-quantity {
  color: var(--thm-black)
}

.cart_table .product-quantity input {
  position: relative;
  top: -2px
}

.cart_table .cart-productname {
  font-weight: 600;
  color: inherit
}

.cart_table .cart-productimage {
  display: inline-block;
  border: 2px solid var(--secondary-color)
}

.cart_table .remove {
  color: var(--thm-base);
  font-size: 18px
}

.cart_table .quantity {
  display: inline-flex;
  align-items: center
}

.cart_table .qut-btn {
  border: 2px solid var(--secondary-color);
  background-color: rgba(0, 0, 0, 0);
  color: #b8c6d0;
  padding: 0;
  width: 30px;
  height: 30px;
  font-size: 16px;
  border-radius: 4px
}

.cart_table .qut-btn:hover {
  background-color: var(--theme-color);
  color: var(--white-color)
}

.cart_table .qty-input {
  vertical-align: middle;
  border: 2px solid var(--secondary-color);
  width: 70px;
  height: 30px;
  font-size: 14px;
  text-align: center;
  color: #2c3e50;
  font-weight: 700;
  margin: 0 10px;
  border-radius: 4px
}

.cart_table .qty-input::-webkit-outer-spin-button,
.cart_table .qty-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0
}

.cart_table .qty-input[type=number] {
  -moz-appearance: textfield
}

.cart_table .actions {
  text-align: right;
  vertical-align: middle
}

.cart_table .actions>.vs-btn {
  margin-right: 15px
}

.cart_table .actions>.vs-btn:last-child {
  margin-right: 0
}

.cart_table .vs-cart-coupon {
  float: left;
  margin: 0;
  width: 455px;
  max-width: 100%;
  display: flex
}

.cart_table .vs-cart-coupon input {
  height: 50px;
  width: calc(100% - 200px);
  margin-right: 10px
}

.cart_totals {
  border: 1px solid #ecf0f1
}

.cart_totals th,
.cart_totals td {
  vertical-align: top;
  padding: 20px 20px;
  border: none;
  border-bottom: 1px solid #ecf0f1;
  font-size: 14px;
  color: var(--thm-black);
  width: 55%
}

.cart_totals th:first-child,
.cart_totals td:first-child {
  width: 45%;
  background-color: #f9fbfb;
  font-weight: 700;
  font-size: 14px;
  color: #333
}

.cart_totals .shipping-calculator-button {
  display: inline-block;
  border-bottom: 1px solid;
  color: var(--thm-black);
  font-weight: 700
}

.cart_totals .shipping-calculator-button:hover {
  color: var(--theme-color)
}

.cart_totals .woocommerce-shipping-destination {
  margin-bottom: 10px
}

.cart_totals .woocommerce-shipping-methods {
  margin-bottom: 0
}

.cart_totals .shipping-calculator-form {
  display: none
}

.cart_totals .shipping-calculator-form p:first-child {
  margin-top: 20px
}

.cart_totals .shipping-calculator-form p:last-child {
  margin-bottom: 0
}

.cart_totals .shipping-calculator-form .vs-btn {
  padding: 5px 30px
}

.cart_totals .amount {
  font-weight: 700
}

.cart_totals .order-total .amount {
  color: #1abc9c
}

@media(max-width: 767px) {
  .cart_table {
    text-align: left;
    min-width: auto;
    border-collapse: separate;
    border-spacing: 0 20px;
    border: none
  }

  .cart_table thead {
    display: none
  }

  .cart_table td {
    padding: 15px;
    display: block;
    width: 100%;
    padding-left: 25%;
    text-align: right;
    border: 1px solid #f3f3f3;
    border-bottom: none
  }

  .cart_table td::before {
    display: block
  }

  .cart_table td:last-child {
    border-bottom: 1px solid #f3f3f3
  }

  .cart_table td.actions {
    padding-left: 15px;
    text-align: center
  }

  .cart_table td.actions>.vs-btn {
    margin-top: 10px;
    margin-right: 0
  }

  .cart_table .vs-cart-coupon {
    width: 100%;
    text-align: center;
    float: none;
    justify-content: center
  }

  .cart_table .vs-cart-coupon input {
    width: calc(100% - 150px)
  }

  .cart_totals th,
  .cart_totals td {
    padding: 15px 15px
  }
}

/**/

/* 404 page not found */
.page-not-found {
  padding: 70px 0px;
}

.not-found {
  padding-top: 0;
}

.not-found p {
  font-size: 200px;
  line-height: 1.1;
  margin-bottom: 20px;
}

.not-found p small {
  display: block;
}

.error-page .error-code {
  display: block;
  font-size: 150px;
  line-height: 150px;
  color: var(--thm-base2);
  margin-bottom: 20px;
  text-shadow: 5px 5px 1px rgba(0, 0, 0, 0.1);
}

.error-page .error-code span {
  color: var(--thm-base);
}

.error-page .error-body .btn {
  margin-top: 30px;
  font-weight: 700;
}

/**/

email::after {
  content: attr(data-domain)
}

email::before {
  content: attr(data-user) "\0040"
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  }
  /*chrome*/
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  }
  .grecaptcha-badge{
    z-index: 9999;
  }
/**/
/* popup css */
.popup-content {
  /* display: none; */
  padding: 20px;
  background: #fff;
  max-width: 800px;
  margin: 0 auto;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  position: relative;
  text-align: center;
}

.popup-content img {
  width: 100%;
}

.mfp-close {
  position: absolute;
  top: -30px;
  /*right: 10px;
   width: 20px;
  height: 20px; */
  line-height: 20px;
  text-align: center;
  color: #fff !important;
  background: transparent;
  border-radius: 50%;
  cursor: pointer;
  font-size: 25px;
  font-weight: bold;
  text-decoration: none;
}

.popup-content .contact-form-wrapper {
  max-width: 100%;
}

/* popup css */
/* Alert Color */
.alert-warning{
  background-color: #fff3cd;
border-color: #ffeeba;
color: #856404;
}
.alert-success {
background-color: #d4edda;
border-color: #c3e6cb;
color: #155724;
}
.alert-info {
background-color: #cce5ff;
border-color: #b8daff;
color: #004085;
}
.alert-danger {
background-color: #f8d7da;
border-color: #f5c6cb;
color: #721c24;
}
.close {
color: #000000;
float: right;
font-size: 16px;
font-weight: 300;
line-height: 20px;
opacity: 0.3;
text-shadow: none;
vertical-align: middle;
}
.close.icon-plus{
  position: relative;
  transform: rotate(45deg);
}
/* end Alert Color */
  /***
===============================================================
End Css 
===============================================================
***/